import React, { useState } from 'react';
import { FilterDropdown } from '../FilterDropdown';
import { useFilter } from '../../../../hooks/useFilter';
import { RiApplicationSourceQueryParamsEnum } from '../../../../constants';

const FILTER_KEY = 'source';

const policyTypeOptions = [
  {
    title: 'Third party',
    name: RiApplicationSourceQueryParamsEnum.ThirdParty,
  },
  {
    title: 'TheGuarantors',
    name: RiApplicationSourceQueryParamsEnum.TheGuarantors,
  },
];

export function PolicyTypeFilter() {
  const { getFilterOptions, handleFilterChange } = useFilter(FILTER_KEY);
  const initialOptions = getFilterOptions(policyTypeOptions);
  const [options, setOptions] = useState(initialOptions);

  const handleOptionClick = (optionValue: string) => {
    const newOptions = options.map((option) =>
      option.name === optionValue ? { ...option, selected: !option.selected } : option,
    );

    setOptions(newOptions);
  };

  const handleOpen = () => {
    setOptions(initialOptions);
  };

  return (
    <FilterDropdown
      data-testid="policy-type-filter"
      title="Policy type"
      options={options}
      onOpen={handleOpen}
      onOptionClick={handleOptionClick}
      onBlur={() => handleFilterChange(options)}
    />
  );
}
