import Cookies from 'js-cookie';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import getEnv from '../env';

export type tokens = {
  accessToken: string;
  refreshToken: string;
};

const useAuthStore = create(
  devtools<{
    accessToken: string;
    refreshToken: string;
    setTokens: ({ accessToken, refreshToken }: tokens) => void;
    logout: () => void;
  }>((set) => {
    const searchParams = new URLSearchParams(window.location.search);

    const searchAccessToken = searchParams.get('access_token');
    const searchRefreshToken = searchParams.get('refresh_token');

    if (searchAccessToken && searchRefreshToken) {
      const host = location.hostname === 'localhost' ? 'localhost' : '.theguarantors.com';
      document.cookie = `auth_tokens=${JSON.stringify({
        access_token: encodeURIComponent(searchAccessToken),
        refresh_token: encodeURIComponent(searchRefreshToken),
      })}; path=/; domain=${host}; samesite=Strict; secure=true;`;
    }
    const cookies = Cookies.get('auth_tokens');
    const tokens = cookies ? JSON.parse(cookies || '') : {};

    const accessToken = tokens.access_token || getEnv().access_token || 'DefaultToken';
    const refreshToken = tokens.refresh_token || 'DefaultToken';

    return {
      accessToken,
      refreshToken,
      setTokens: (tokens: tokens) => {
        set((state) => ({
          ...state,
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
        }));
      },
      logout: () => {
        Cookies.remove('auth_tokens');
        set((state) => ({
          ...state,
          accessToken: '',
          refreshToken: '',
        }));
      },
    };
  }),
);
export default useAuthStore;
