import React, { ReactNode, useEffect } from 'react';
import { useRiApplicationPermissions } from '@/utils/apiRestCalls/ri-application-permissions.api';
import useRiPermissionsStore from '@/stores/ri-permissions.store';
import { saveRiPermissionsToStorage } from '@/utils/storage/ri-permissions.storage';

type Props = {
  children: ReactNode;
};

export function RiWrapper({ children }: Props) {
  const setRiPermissions = useRiPermissionsStore((state) => state.setRiPermissions);
  const { data: riPermissionsResponse, isFetched } = useRiApplicationPermissions();

  useEffect(() => {
    if (isFetched) {
      const riPermissions = riPermissionsResponse?.result || [];
      setRiPermissions(riPermissions);

      saveRiPermissionsToStorage(riPermissions);
    }
  }, [isFetched]);

  return <>{children}</>;
}
