import { AllValues } from '@/utils/types/types.util';
import { RiPermissions } from '@/constants/ri-permissions.constant';

const KEY = 'ri-permissions';

export function loadRiPermissionsFromStorage(): AllValues<typeof RiPermissions>[] {
  try {
    const jsonItem = localStorage.getItem(KEY) ?? JSON.stringify([]);

    return JSON.parse(jsonItem);
  } catch (error) {
    return [];
  }
}

export function saveRiPermissionsToStorage(riPermissions: AllValues<typeof RiPermissions>[]) {
  localStorage.setItem(KEY, JSON.stringify(riPermissions));
}
