import {
  ReasonsNotCompliantToGeneralReasonErrorMap,
  ReasonsNotCompliantToMessageMap,
  RiCoverageToCoverageReasonMap,
} from '../constants';
import { ObjectKeys, StringUnion } from '../types';
import { capitalize } from '@mui/material/utils';

type ReasonsNotCompliantMessage =
  | (typeof ReasonsNotCompliantToMessageMap)[keyof typeof ReasonsNotCompliantToMessageMap]
  | StringUnion;
export function getReasonsNotCompliantMessage(reasons: string[]): ReasonsNotCompliantMessage[] {
  const messages = reasons.map((reason) => {
    const mappedMessage =
      ReasonsNotCompliantToMessageMap[reason as keyof typeof ReasonsNotCompliantToMessageMap];

    if (!mappedMessage) {
      const formattedReason = reason
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ')
        .toLowerCase();

      return capitalize(formattedReason);
    }

    return mappedMessage;
  });

  return messages;
}

interface RiApplicationGeneralReasonErrors {
  coverageDatesError: boolean;
  policyStatusError: boolean;
  renterNameError: boolean;
  addressError: boolean;
}
/**
 * Generates general application reason errors based on the reasons not compliant.
 * It does not include coverage-related reasons, since those are handled separately.
 * @param reasonsNotCompliant - An array of reasons not compliant.
 * @returns An object containing general application reason errors.
 */
export function generateGeneralApplicationReasonErrors(
  reasonsNotCompliant: string[],
): RiApplicationGeneralReasonErrors {
  const generalReasonErrors: RiApplicationGeneralReasonErrors = {
    coverageDatesError: false,
    policyStatusError: false,
    renterNameError: false,
    addressError: false,
  };

  for (const reason of reasonsNotCompliant) {
    const reasonErrorKey =
      ReasonsNotCompliantToGeneralReasonErrorMap[
        reason as ObjectKeys<typeof ReasonsNotCompliantToGeneralReasonErrorMap>
      ];

    if (reasonErrorKey) {
      generalReasonErrors[reasonErrorKey] = true;
    }
  }

  return generalReasonErrors;
}

export function getCoverageReasonError(
  coverageKey: string,
  reasonsNotCompliant: string[],
): boolean {
  const coverageRelatedReasons =
    RiCoverageToCoverageReasonMap[coverageKey as keyof typeof RiCoverageToCoverageReasonMap];

  if (!coverageRelatedReasons) {
    return false;
  }

  return coverageRelatedReasons.some((reason) => reasonsNotCompliant.includes(reason));
}
