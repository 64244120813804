import { TypographyOptions } from '@mui/material/styles/createTypography';
import breakpoints from './themeBreakpoints';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Iver: React.CSSProperties;
    h2Iver: React.CSSProperties;
    subheader: React.CSSProperties;
    helpertext: React.CSSProperties;
    cellmain: React.CSSProperties;
    cellsub: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Iver?: React.CSSProperties;
    h2Iver?: React.CSSProperties;
    subheader: React.CSSProperties;
    helpertext: React.CSSProperties;
    cellmain: React.CSSProperties;
    cellsub: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Iver: true;
    h2Iver: true;
    subheader: true;
    helpertext: true;
    cellmain: true;
    cellsub: true;
  }
}

const typography: TypographyOptions = {
  fontFamily: 'Acid Grotesk' as string,
  allVariants: {
    color: 'rgba(29, 29, 29, 1)' as string,
  },
  h1: {
    fontSize: '72px' as string,
    letterSpacing: '-2px',
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '40px',
    },
  },
  h1Iver: {
    fontSize: '72px' as string,
    display: 'block',
    fontFamily: 'Ivar Display' as string,
    letterSpacing: '-2px',
    paddingBottom: 8,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '28px',
    },
  },
  h2: {
    fontSize: '64px' as string,

    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '36px',
    },
  },
  h2Iver: {
    fontSize: '64px' as string,
    display: 'block',
    fontFamily: 'Ivar Display' as string,
    fontWeight: 400,
    letterSpacing: '-2%',
    paddingBottom: 8,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '36px',
    },
  },
  h3: {
    fontSize: '42px' as string,
    paddingBottom: 8,

    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '28px',
    },
  },

  h4: {
    fontSize: '32px' as string,
    fontWeight: 400 as number,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '22px',
    },
  },
  h5: {
    fontSize: '24px' as string,
    fontWeight: 400 as number,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '20px',
    },
  },
  h6: {
    fontSize: '20px' as string,
    fontWeight: 400 as number,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '18px',
    },
  },
  subtitle1: {
    fontSize: '20px' as string,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: '18px' as string,
  },
  subheader: {
    fontSize: '16px',
  },
  body1: {
    fontSize: '16px' as string,
    [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
      fontSize: '14px',
    },
  },
  body2: {
    fontSize: '14px' as string,
    lineHeight: '24px', //this is for Premium
  },
  caption: {
    fontSize: '12px' as string,
    lineHeight: '16px' as string,
    letterSpacing: '.4px',
  },
  helpertext: {
    color: '#777' as string,
    fontFeatureSettings: "'clig' off, 'liga' off" as string,
    fontFamily: 'Acid Grotesk' as string,
    fontSize: '12px' as string,
    fontStyle: 'normal' as string,
    fontWeight: 400 as number,
    lineHeight: '16px' as string,
    marginTop: '0px' as string,
    marginBottom: '0px' as string,
  },
  cellmain: {
    lineHeight: '26px' as string,
    display: 'block' as string,
  },
  cellsub: {
    lineHeight: '16px' as string,
    fontSize: '12px' as string,
    display: 'block' as string,
  },
};

export default typography;
