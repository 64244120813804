import { Drawer, IconButton, List, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TheGuarantorsLogo from '../../assets/images/updated-logo.png';
import { MenuContent } from './MenuContent';
import { CloseOutlined } from '@mui/icons-material';
import useMenu from './useMenu';

export const MobileMenu = () => {
  const { menuOpen, openMenu, closeMenu } = useMenu();

  return (
    <>
      <IconButton
        aria-label="open mobile menu drawer"
        edge="start"
        onClick={openMenu}
        sx={{ mr: 2, display: { xs: 'relative', md: 'none', position: 'absolute' } }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        variant="temporary"
        open={menuOpen}
        anchor="left"
        onClose={closeMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '322px',
            maxWidth: '90%',
            borderRadius: '0px 16px 16px 0px',
          },
          display: { md: 'none' },
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            my={2}
            pb={2}
            mb={3}
            textAlign="center"
            sx={{ boxShadow: '4px 8px 22px rgba(0, 0, 0, 0.08)' }}
          >
            <img height="24px" src={TheGuarantorsLogo} alt="TheGuarantors" />
          </Box>
          <Box sx={{ position: 'absolute', left: 'auto', top: '10px', right: '20px' }}>
            <IconButton aria-label="open mobile menu drawer" edge="start" onClick={closeMenu}>
              <CloseOutlined />
            </IconButton>
          </Box>

          <List sx={{ padding: 0, height: 'calc(100vh - 85px)' }}>
            <MenuContent menuOpen={true} />
          </List>
        </Box>
      </Drawer>
    </>
  );
};
