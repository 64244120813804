import { useNavigate } from 'react-router';

import { HOSTNAME, UTM_CODES } from '../constants/Constants';
import { SegmentObj, Target } from './types/LinkTypes.interface';
import { trackEvent } from './analytics';
import { cachedSessionStorage } from './cachedSessionStorage';

export type UTMKeys = (typeof UTM_CODES)[number];

export type UTMRecord = Partial<Record<UTMKeysWithoutPrefix, string>>;

export const isUTMKey = (str: string): str is UTMKeys => {
  return UTM_CODES.includes(str as UTMKeys);
};

export type UTMKeysWithoutPrefix = UTMKeys extends `utm_${infer Name}` ? Name : void;

export const getUTMKeyWithoutPrefix = (key: string): UTMKeysWithoutPrefix => {
  // This cast is guaranteed by implementation
  return key.replace('utm_', '') as UTMKeysWithoutPrefix;
};

export const createUrlWithUTM = (path: string | URL) => {
  const url = new URL(path);

  if (url.hostname.toLowerCase().includes(HOSTNAME)) {
    const utms = cachedSessionStorage.getUTM();

    Object.entries(utms).forEach(([param, value]) => {
      if (value) {
        url.searchParams.set(`utm_${param}`, value);
      }
    });
  }

  return url;
};

export const goToExternal = (path: string, segmentObj?: SegmentObj, target?: Target) => {
  const url = createUrlWithUTM(path);

  if (segmentObj) {
    trackEvent(`${segmentObj.title} Button is clicked`, {
      referrer: window.location.pathname,
      url: path,
      ...segmentObj,
    });
  }

  const targetToUse = !!target ? target : Target.blank;

  window.open(url, targetToUse);
};

export const useNavigateWithSegment = () => {
  const navigate = useNavigate();

  return (url: string, segmentObj?: SegmentObj) => {
    if (segmentObj) {
      trackEvent(`${segmentObj.title} Button is clicked`, {
        referrer: window.location.pathname,
        url: url,
        ...segmentObj,
      });
    }

    navigate(url);
  };
};
