export const RiPermissions = {
  RiApplications: {
    FullRead: 'll-portal-backend.ri.applications.read',
    /**
     * Not used for now, but for a possible future use
     * of the permissions
     */
    ApplicationListRead: 'll-portal-backend.ri.applications.application-list-read',
    ApplicationDetailRead: 'll-portal-backend.ri.applications.application-detail-read',
  },
} as const;
