import { RiTableErrorIcon } from './RiTableErrorIcon';
import { MuiSxCollection } from '../../types';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const emptyTableStyles = {
  row: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cell: {
    paddingY: '60px',
    paddingX: '0',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  contentTitle: {
    color: '#595959',
    lineHeight: '28px',
  },
} satisfies MuiSxCollection;

export function RiTableEmpty() {
  return (
    <TableRow sx={emptyTableStyles.row}>
      <TableCell colSpan={1000} sx={emptyTableStyles.cell}>
        <Box sx={emptyTableStyles.box}>
          <RiTableErrorIcon />
          <Box sx={emptyTableStyles.content}>
            <Typography component="h3" variant="h6" sx={emptyTableStyles.contentTitle}>
              No results found
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
