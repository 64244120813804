import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  menuOpen: false,
};

const useMenu = create(
  devtools<{
    menuOpen: boolean;
    openMenu: () => void;
    closeMenu: () => void;
    clear: () => void;
  }>((set) => ({
    ...initialState,
    closeMenu: () =>
      set((state) => ({
        ...state,
        menuOpen: false,
      })),
    openMenu: () =>
      set((state) => ({
        ...state,
        menuOpen: true,
      })),
    clear: () => set(initialState),
  })),
);

export default useMenu;
