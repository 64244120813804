import { Fragment } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { MuiSxCollection } from '../../types';
import { useRiApplication } from '../../api';
import {
  generateGeneralApplicationReasonErrors,
  getReasonsNotCompliantMessage,
  parseRiApplicationDetails,
} from '../../utils';
import { ComplianceStatusTag } from '../ComplianceStatusTag';
import { RI_DETAILED_APPLICATION_FALLBACK_VALUE, RiSegmentEvents } from '../../constants';
import { RiApplicationDetailsAddress } from '../RiApplicationDetailsDrawer/RiApplicationDetailsAddress';
import { RiApplicationDetailsField } from '../RiApplicationDetailsDrawer/RiApplicationDetailsField';
import { RiApplicationDetailsError } from '../RiApplicationDetailsDrawer/RiApplicationDetailsError';
import { trackEvent } from '@/utils/analytics';

interface RiApplicationDetailsDrawerProps {
  isOpen: boolean;
  applicationId: string;
  onClose: () => void;
}

const drawerStyles = {
  paper: {
    padding: '32px',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    boxSizing: 'border-box',
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  drawerHeaderTitle: {
    lineHeight: '46px',
  },
  drawerBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
  },
  mainPolicyData: {
    border: '1px solid #080A2D4D',
    backgroundColor: '#080A2D0A',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '16px',
    borderRadius: '4px',
  },
  mainPolicyDataComplianceStatus: {
    justifyContent: 'flex-end',
  },
  mainPolicyDataReasonList: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    textAlign: 'right',
  },
  mainPolicyDataReasonItem: {
    color: '#595959',
    fontSize: '14px',
    lineHeight: '24px',
  },
  policyHolders: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  policyHoldersSubtitle: {
    marginBottom: '8px',
  },
  coverages: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  coveragesList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
} satisfies MuiSxCollection;

export function RiApplicationDetailsDrawer({
  isOpen,
  onClose,
  applicationId,
}: RiApplicationDetailsDrawerProps) {
  const { data, isError, error, status, fetchStatus } = useRiApplication({
    applicationId,
    enabled: isOpen,
  });
  const isLoading = status === 'pending' || fetchStatus === 'fetching';

  const applicationDetails = data?.result;
  const parsedDetails = parseRiApplicationDetails(applicationDetails);
  const { additionalInsuredRoommates, additionalInterests, reasonsNotCompliant } = parsedDetails;
  const { core: coreCoverages, deductibles: deductiblesCoverages } = parsedDetails.coverages;
  const generalReasonErrors = generateGeneralApplicationReasonErrors(reasonsNotCompliant);

  function handleDrawerTrack() {
    trackEvent(RiSegmentEvents.RiApplicationDrawerClick, {
      applicationId,
    });
  }

  return (
    <Drawer
      PaperProps={{
        sx: drawerStyles.paper,
        onClick: handleDrawerTrack,
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Box component={'header'} sx={drawerStyles.drawerHeader}>
        <Typography component={'h2'} variant={'h4'} sx={drawerStyles.drawerHeaderTitle}>
          Policy details
        </Typography>
        <IconButton onClick={onClose} aria-label="close drawer">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box component={'section'} sx={drawerStyles.drawerBody}>
        {isError ? <RiApplicationDetailsError error={error} /> : null}
        <Box component={'article'} sx={drawerStyles.mainPolicyData}>
          <RiApplicationDetailsField
            label="Policy number"
            isLoading={isLoading}
            isFetchError={isError}
            skeletonProps={{
              width: 100,
            }}
            value={parsedDetails.policyNumber}
          />
          <RiApplicationDetailsField
            label="Compliance status"
            isLoading={isLoading}
            isFetchError={isError}
            skeletonProps={{
              width: 145,
            }}
            value={
              <Fragment>
                {parsedDetails.complianceStatus ? (
                  <ComplianceStatusTag
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                    statusTextProps={{
                      sx: {
                        lineHeight: 'inherit',
                        fontSize: 'inherit',
                      },
                    }}
                    value={parsedDetails.complianceStatus}
                    reasons={reasonsNotCompliant}
                    showReasons={false}
                  />
                ) : (
                  RI_DETAILED_APPLICATION_FALLBACK_VALUE
                )}
                {reasonsNotCompliant.length > 0 ? (
                  <Box sx={drawerStyles.mainPolicyDataReasonList} component={'span'}>
                    {getReasonsNotCompliantMessage(reasonsNotCompliant).map((reason) => (
                      <Typography
                        key={reason}
                        component={'span'}
                        sx={drawerStyles.mainPolicyDataReasonItem}
                      >
                        {reason}
                      </Typography>
                    ))}
                  </Box>
                ) : null}
              </Fragment>
            }
          />
          <RiApplicationDetailsField
            label="Provider"
            isLoading={isLoading}
            isFetchError={isError}
            skeletonProps={{
              width: 100,
            }}
            value={parsedDetails.policyCarrierProvider}
          />
        </Box>
        <Box component={'article'} sx={drawerStyles.policyHolders}>
          <Typography component={'h3'} variant={'h6'}>
            Policy holders
          </Typography>
          <Box>
            <Typography sx={drawerStyles.policyHoldersSubtitle}>Primary</Typography>
            <RiApplicationDetailsField
              label={parsedDetails.renterFullname}
              skeletonProps={{
                width: 150,
              }}
              isLoading={isLoading ? 'all' : false}
              isFetchError={isError}
              isReasonError={generalReasonErrors.renterNameError}
              reasonErrorField="label"
              value={parsedDetails.email}
            />
          </Box>
          <Divider />
          <Box>
            <Typography sx={drawerStyles.policyHoldersSubtitle}>Additionally insured</Typography>
            {additionalInsuredRoommates.map((roommate) => {
              return (
                <RiApplicationDetailsField
                  key={roommate.key}
                  label={roommate.fullName}
                  value={roommate.email}
                  isLoading={isLoading ? 'all' : false}
                  skeletonProps={{
                    width: 150,
                  }}
                />
              );
            })}
          </Box>
          <Divider />
          <Box>
            <Typography sx={drawerStyles.policyHoldersSubtitle}>Interested party</Typography>
            {additionalInterests.map((interest) => {
              return (
                <RiApplicationDetailsField
                  key={interest.key}
                  label={interest.fullName}
                  value={interest.email}
                  isLoading={isLoading ? 'all' : false}
                  skeletonProps={{
                    width: 150,
                  }}
                />
              );
            })}
          </Box>
        </Box>
        <Box component={'article'} sx={drawerStyles.coverages}>
          <Typography component={'h3'} variant={'h6'}>
            Coverage details
          </Typography>
          <Box display="flex" flexDirection="column" rowGap="8px">
            <RiApplicationDetailsField
              label="Policy status"
              value={parsedDetails.policyStatus}
              isLoading={isLoading}
              isFetchError={isError}
              skeletonProps={{
                width: 50,
              }}
              isReasonError={generalReasonErrors.policyStatusError}
            />
            <Divider />
            <RiApplicationDetailsField
              label="Coverage start"
              value={parsedDetails.policyEffectiveDate}
              isLoading={isLoading}
              isFetchError={isError}
              skeletonProps={{
                width: 65,
              }}
              isReasonError={generalReasonErrors.coverageDatesError}
            />
            <RiApplicationDetailsField
              label="Coverage end"
              value={parsedDetails.policyExpirationDate}
              isLoading={isLoading}
              isFetchError={isError}
              skeletonProps={{
                width: 65,
              }}
              isReasonError={generalReasonErrors.coverageDatesError}
            />
            <Box sx={drawerStyles.coveragesList}>
              <Divider />
              {coreCoverages.map((coverage, index, array) => {
                const isLastCoverage = index === array.length - 1;

                return (
                  <Fragment key={coverage.label}>
                    <RiApplicationDetailsField
                      label={coverage.label}
                      value={coverage.value}
                      isLoading={isLoading}
                      isFetchError={isError}
                      isReasonError={coverage.hasReasonError}
                      skeletonProps={{
                        width: 50,
                      }}
                      sx={{
                        paddingBottom: isLastCoverage ? '4px' : undefined,
                      }}
                    />
                    {isLastCoverage ? null : <Divider />}
                  </Fragment>
                );
              })}
              <Divider />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" rowGap="8px">
            <Typography component={'h3'} variant={'h6'}>
              Deductibles
            </Typography>
            <Box sx={drawerStyles.coveragesList}>
              {deductiblesCoverages.map((coverage, index, array) => {
                const isLastCoverage = index === array.length - 1;

                return (
                  <Fragment key={coverage.label}>
                    <RiApplicationDetailsField
                      label={coverage.label}
                      value={coverage.value}
                      isLoading={isLoading}
                      isFetchError={isError}
                      isReasonError={coverage.hasReasonError}
                      skeletonProps={{
                        width: 50,
                      }}
                    />
                    {isLastCoverage ? null : <Divider />}
                  </Fragment>
                );
              })}
            </Box>
          </Box>
        </Box>
        <RiApplicationDetailsAddress
          address={parsedDetails.address}
          isLoading={isLoading}
          isReasonError={generalReasonErrors.addressError}
          buildingUnit={parsedDetails.buildingUnit}
        />
      </Box>
    </Drawer>
  );
}
