import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { riApplicationsTableColumns } from './ri-applications-table-columns';
import { useSearchParams } from 'react-router-dom';
import { useRiApplicationList } from '../../api';
import { RiListApplication } from '../../types';
import {
  getRiApplicationsTableColumnMeta,
  getRiSortByParamByColumnId,
  mapRiSortingToTableState,
  mergeSx,
  parseFiltersFromParams,
  parsePaginationFromParams,
  parseSortFromParams,
} from '../../utils';
import { RiTableBody, RiTableHead, RiTableHeader, RiTableRoot, RiTableRow } from '../../components';
import { RiApplicationTableHeader } from './RiApplicationTableHeader';
import { RiApplicationsTableRow } from './RiApplicationsTableRow';
import { RiApplicationFooter } from './RiApplicationFooter';
import {
  DefaultRiSort,
  RiSearchParamKeys,
  RiSegmentEvents,
} from '@/features/RentersInsurance/constants';
import { trackEvent } from '@/utils/analytics';

export function RiApplicationsTable() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { currentLimit, currentPage } = parsePaginationFromParams(searchParams);
  const { search, source, complianceStatus, policyStatus } = parseFiltersFromParams(searchParams);
  const { sortBy, sortOrder } = parseSortFromParams(searchParams);

  const {
    data: listApplicationsData,
    status,
    fetchStatus,
  } = useRiApplicationList({
    limit: currentLimit,
    page: currentPage,
    search,
    source,
    complianceStatus,
    policyStatus,
    sortBy,
    sortOrder,
  });

  const isInitialLoading = status === 'pending';
  const isDataLoading = isInitialLoading || fetchStatus === 'fetching';
  const isDataEmpty = listApplicationsData?.result.length === 0 && !isDataLoading;
  const isDataError = status === 'error' && !isDataLoading;

  const results = listApplicationsData?.result ?? [];

  const table = useReactTable<RiListApplication>({
    columns: riApplicationsTableColumns,
    data: results,
    getCoreRowModel: getCoreRowModel(),
    rowCount: listApplicationsData?.pagination.totalCount ?? 0,
    state: {
      pagination: {
        pageSize: currentLimit,
        pageIndex: currentPage - 1,
      },
      sorting: mapRiSortingToTableState(sortBy, sortOrder),
    },
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: false,
    sortingFns: undefined as never,
  });

  const tableHeaderGroups = table.getHeaderGroups();
  const tableRows = table.getRowModel().rows;

  return (
    <RiTableRoot
      layoutMode="fixed"
      headerSlot={<RiApplicationTableHeader rowsToExport={results} isDataLoading={isDataLoading} />}
      footer={
        <RiApplicationFooter
          isInitialLoading={isInitialLoading}
          count={listApplicationsData?.pagination.totalCount ?? 0}
        />
      }
    >
      <RiTableHeader>
        {tableHeaderGroups.map((headerGroup) => {
          return (
            <RiTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnMeta = getRiApplicationsTableColumnMeta(header);
                const headerWidth = header.column.columnDef.size ?? '100%';
                const headerSx = mergeSx(
                  {
                    width: headerWidth,
                  },
                  columnMeta.headerSx,
                );

                function handleSort(sort: 'asc' | 'desc') {
                  const sortOrder = sort === 'asc' ? 'asc' : 'desc';
                  const sortBy = getRiSortByParamByColumnId(header.id);

                  trackEvent(RiSegmentEvents.RiApplicationSortOrderChange, {
                    sortType: header.id === 'source' ? 'policyType' : header.id,
                    sortOrder: sortOrder,
                  });

                  setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);

                    if (sortBy === DefaultRiSort.SortBy) {
                      params.delete(RiSearchParamKeys.SortBy);
                    } else {
                      params.set(RiSearchParamKeys.SortBy, sortBy);
                    }

                    if (sortOrder === DefaultRiSort.SortOrder) {
                      params.delete(RiSearchParamKeys.SortOrder);
                    } else {
                      params.set(RiSearchParamKeys.SortOrder, sortOrder);
                    }

                    return params;
                  });
                }

                return (
                  <RiTableHead
                    sortable={header.column.getCanSort()}
                    sortDirection={header.column.getIsSorted()}
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={headerSx}
                    onSort={handleSort}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </RiTableHead>
                );
              })}
            </RiTableRow>
          );
        })}
      </RiTableHeader>
      <RiTableBody
        isEmpty={isDataEmpty}
        isLoading={isDataLoading}
        isError={isDataError}
        loadingColumns={riApplicationsTableColumns.length}
        loadingRows={currentLimit}
      >
        {tableRows.map((row) => {
          return <RiApplicationsTableRow key={row.id} row={row} />;
        })}
      </RiTableBody>
    </RiTableRoot>
  );
}
