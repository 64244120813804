import {
  RiApplicationSourceEnum,
  DisplayedRiApplicationSourceEnum,
  RiSortByParamEnum,
  RiSortOrderParamEnum,
  RiSortToRiTableColumnIdMap,
  DefaultRiSort,
  RiTableColumnIdToSortMap,
} from '../constants';
import { AppTheme, RiListApplication } from '../types';
import { SxProps } from '@mui/material';
import { Cell, Header, SortingState } from '@tanstack/react-table';

export function mapRiApplicationSourceToDisplayedValue(source: RiApplicationSourceEnum) {
  return source === RiApplicationSourceEnum.Msi
    ? DisplayedRiApplicationSourceEnum.TheGuarantors
    : DisplayedRiApplicationSourceEnum.ThirdParty;
}

export interface RiApplicationsTableColumnMeta {
  cellSx?: SxProps<AppTheme>;
  headerSx?: SxProps<AppTheme>;
  maxWidth?: number;
}
type TableDataItem = Cell<RiListApplication, unknown> | Header<RiListApplication, unknown>;
export function getRiApplicationsTableColumnMeta(data: TableDataItem) {
  return (data.column.columnDef.meta ?? {}) as RiApplicationsTableColumnMeta;
}

export function mapRiSortingToTableState(
  sortBy: RiSortByParamEnum,
  sortOrder: RiSortOrderParamEnum,
): SortingState {
  const mappedId = RiSortToRiTableColumnIdMap[sortBy];

  return [
    {
      id: mappedId ?? RiSortToRiTableColumnIdMap[DefaultRiSort.SortBy],
      desc: sortOrder === RiSortOrderParamEnum.Desc,
    },
  ];
}

export function getRiSortByParamByColumnId(columnId: string): RiSortByParamEnum {
  return (
    RiTableColumnIdToSortMap[columnId as keyof typeof RiTableColumnIdToSortMap] ??
    DefaultRiSort.SortBy
  );
}
