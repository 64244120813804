import { safeParse } from './JSON';
import { UTMRecord, getUTMKeyWithoutPrefix, isUTMKey } from './linkUtils';
import { Dictionary } from './types/shared.interface';

export class SessionStorage {
  private state: Dictionary = {};
  private utm: UTMRecord = {};
  private UTM_KEY = 'utm';

  cacheStorage = (): void => {
    Object.keys(sessionStorage).forEach((key) => {
      const value = this.getItem(key);

      if (!value) {
        return;
      }

      if (key === this.UTM_KEY) {
        this.utm = safeParse(value);
        return;
      }

      this.state[key] = value;
    });
  };

  /**
   * Does a key lookup from in-memory cache and sessionStorage
   * This returns string because it was the most backward compatible way for this repo
   */
  getItem = <T extends string = string>(key: string): T | null => {
    if (this.state[key]) {
      return this.state[key] as T;
    }

    return sessionStorage.getItem(key) as T;
  };

  /**
   * Adds a key-value pair to the sessionStorage and in-memory cache
   * This expects string because it was the most backward compatible way for this repo
   */
  setItem = (key: string, value: string): void => {
    this.state[key] = value;

    sessionStorage.setItem(key, value);
  };

  removeItem = (key: string): void => {
    delete this.state[key];

    sessionStorage.removeItem(key);
  };

  /**
   * Since we know utm is easier to work with as an object
   * We add the convience method to store it and get it
   */

  getUTM = (): UTMRecord => {
    const item = sessionStorage.getItem(this.UTM_KEY);

    if (!item) {
      return this.utm;
    }

    return safeParse<UTMRecord>(item);
  };

  storeUTM = (searchParams: URLSearchParams) => {
    searchParams.forEach((value, key) => {
      if (value == null) {
        return;
      }

      if (isUTMKey(key)) {
        const normalizedKey = getUTMKeyWithoutPrefix(key);
        this.utm[normalizedKey] = value;
      }

      sessionStorage.setItem(this.UTM_KEY, JSON.stringify(this.utm));
    });
  };
}

export const cachedSessionStorage = new SessionStorage();
