import { Box } from '@mui/material';
import { SideMenu } from './Menu/SideMenu';
import useMenu from './Menu/useMenu';
import ProfileDrawer from './ProfileDrawer';
import SiteFooter from './SiteFooter';
import { useUrlChecker } from '../hooks/useUrlChecker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SiteHeader from './SiteHeader';
import SiteFooterPublic from './SiteFooterPublic';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { routes } from '../constants/Constants';
import { ToastProvider } from '../components/Toast';
import { RiWrapper } from '@/components/wrapper/RiWrapper';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export const PageWrapper = ({ children }: Props) => {
  const { menuOpen } = useMenu();
  const { isPublic } = useUrlChecker();
  const preRegister = isPublic || window.location.pathname === routes.unitsOwnedEnroll;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RiWrapper>
        <ToastProvider>
          <SiteHeader />
          {/* Viewheight minus header.*/}
          <div id="page-container" style={{ minHeight: 'calc(100vh - 148px)' }}>
            {preRegister ? null : <SideMenu />}
            <Box
              sx={{
                px: 3,
                marginLeft: { md: menuOpen ? '303px' : isPublic ? '0px' : '80px' },
                transition: 'margin 0.3s linear',
              }}
            >
              {/* Viewheight minus header and footer is page content */}
              <Box
                sx={{
                  minHeight: preRegister
                    ? 'calc(100vh - 148px - 445px)'
                    : 'calc(100vh - 148px - 130px)',
                  width: '100%',
                  margin: { xl: '0 auto' },
                }}
              >
                {children}
              </Box>
              {isPublic ? null : <SiteFooter />}
            </Box>
            {isPublic ? <SiteFooterPublic /> : null}
            <ProfileDrawer />
          </div>
        </ToastProvider>
      </RiWrapper>
    </LocalizationProvider>
  );
};
