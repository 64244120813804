import React from 'react';

function SvgComponent({ width = 30, height = 30, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 21 18" fill="none" {...props}>
      <path
        d="M14.7232 5.00976C15.1033 5.00976 15.4737 5.039 15.8343 5.08773C15.1715 2.19299 12.1306 0 8.47559 0C4.33328 0 0.970703 2.80702 0.970703 6.26707C0.970703 8.26513 2.09156 10.0293 3.83621 11.1794L2.8518 13.1579L5.54186 11.9981C6.11691 12.2028 6.7212 12.3685 7.36448 12.4562C7.27676 12.0761 7.22803 11.6862 7.22803 11.2768C7.21828 7.82653 10.5809 5.00976 14.7232 5.00976ZM10.9707 2.81677C11.0936 2.81677 11.2153 2.84097 11.3288 2.888C11.4423 2.93502 11.5455 3.00394 11.6323 3.09082C11.7192 3.17771 11.7882 3.28086 11.8352 3.39438C11.8822 3.5079 11.9064 3.62957 11.9064 3.75245C11.9064 3.87532 11.8822 3.99699 11.8352 4.11051C11.7882 4.22403 11.7192 4.32718 11.6323 4.41407C11.5455 4.50095 11.4423 4.56987 11.3288 4.6169C11.2153 4.66392 11.0936 4.68812 10.9707 4.68812C10.7226 4.68812 10.4846 4.58954 10.3091 4.41407C10.1336 4.23859 10.0351 4.0006 10.0351 3.75245C10.0351 3.50429 10.1336 3.2663 10.3091 3.09082C10.4846 2.91535 10.7226 2.81677 10.9707 2.81677ZM5.97072 4.69787C5.72256 4.69787 5.48457 4.59929 5.30909 4.42381C5.13362 4.24834 5.03504 4.01035 5.03504 3.76219C5.03504 3.51404 5.13362 3.27604 5.30909 3.10057C5.48457 2.9251 5.72256 2.82652 5.97072 2.82652C6.21887 2.82652 6.45686 2.9251 6.63234 3.10057C6.80781 3.27604 6.90639 3.51404 6.90639 3.76219C6.90639 4.01035 6.80781 4.24834 6.63234 4.42381C6.45686 4.59929 6.21887 4.69787 5.97072 4.69787Z"
        fill="white"
      />
      <path
        d="M20.9717 11.2767C20.9717 8.50869 18.1744 6.26697 14.7241 6.26697C11.2738 6.26697 8.47656 8.50869 8.47656 11.2767C8.47656 14.0448 11.2738 16.2865 14.7241 16.2865C15.2894 16.2865 15.8353 16.2085 16.3518 16.0916L19.7242 17.5438L18.5546 15.2046C20.0166 14.2884 20.9717 12.8849 20.9717 11.2767ZM12.843 10.9648C12.658 10.9648 12.4771 10.91 12.3232 10.8071C12.1693 10.7043 12.0494 10.5582 11.9786 10.3872C11.9078 10.2163 11.8892 10.0281 11.9253 9.84662C11.9615 9.66512 12.0506 9.49839 12.1814 9.36754C12.3123 9.23668 12.479 9.14757 12.6605 9.11146C12.842 9.07536 13.0301 9.09389 13.2011 9.16471C13.3721 9.23553 13.5182 9.35546 13.621 9.50933C13.7238 9.6632 13.7787 9.8441 13.7787 10.0292C13.7885 10.5457 13.3596 10.9648 12.843 10.9648ZM16.5955 10.9648C16.3473 10.9648 16.1093 10.8663 15.9339 10.6908C15.7584 10.5153 15.6598 10.2773 15.6598 10.0292C15.6598 9.781 15.7584 9.54301 15.9339 9.36754C16.1093 9.19206 16.3473 9.09348 16.5955 9.09348C16.8436 9.09348 17.0816 9.19206 17.2571 9.36754C17.4326 9.54301 17.5312 9.781 17.5312 10.0292C17.5312 10.2773 17.4326 10.5153 17.2571 10.6908C17.0816 10.8663 16.8436 10.9648 16.5955 10.9648Z"
        fill="white"
      />
    </svg>
  );
}

const WechatIcon = React.memo(SvgComponent);

WechatIcon.displayName = 'WechatIcon';

export default WechatIcon;
