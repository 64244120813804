import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  profileDrawerShown: false,
};

const useProfileDrawerStore = create(
  devtools<{
    profileDrawerShown: boolean;
    showProfileDrawer: () => void;
    hideProfileDrawer: () => void;
    clear: () => void;
  }>((set) => ({
    ...initialState,
    showProfileDrawer: () =>
      set((state) => ({
        ...state,
        profileDrawerShown: true,
      })),
    hideProfileDrawer: () =>
      set((state) => ({
        ...state,
        profileDrawerShown: false,
      })),
    clear: () => set(initialState),
  })),
);

export default useProfileDrawerStore;
