export const ReasonsNotCompliantToMessageMap = {
  POLICY_NOT_FOUND: 'Active policy not found',
  NAME_DOES_NOT_MATCH: 'Name does not match',
  ADDRESS_DOES_NOT_MATCH: 'Address does not match',
  PERSONAL_PROPERTY: 'Insufficient property coverage',
  PERSONAL_LIABILITY: 'Insufficient liability coverage',
  ACTIVE_POLICY_NOT_FOUND: 'Active policy not found',
  COVERAGE_DOES_NOT_MATCH: 'Coverage information unavailable',
  POLICY_DATES_INVALID: 'Policy dates are invalid',
  MEDICAL_PAYMENTS: 'Insufficient medical liability',
  POLICY_NOT_ACTIVE: 'Policy not active',
  INACTIVE_POLICY: 'Inactive policy',
  InactivePolicy: 'Inactive policy',
  PolicyDatesInvalid: 'Policy dates are invalid',
  UPLOAD: 'Upload',
  COVIE_SYNC: 'Third party sync',
  MSI_SYNC: 'TheGuarantors sync',
} as const;

export const ReasonsNotCompliantToGeneralReasonErrorMap = {
  NAME_DOES_NOT_MATCH: 'renterNameError',
  ADDRESS_DOES_NOT_MATCH: 'addressError',
  ACTIVE_POLICY_NOT_FOUND: 'policyStatusError',
  POLICY_DATES_INVALID: 'coverageDatesError',
  POLICY_NOT_ACTIVE: 'policyStatusError',
  INACTIVE_POLICY: 'policyStatusError',
  InactivePolicy: 'policyStatusError',
  PolicyDatesInvalid: 'coverageDatesError',
} as const;

export const RiCoverageToCoverageReasonMap = {
  personalLiabilityCoverage: ['PERSONAL_LIABILITY', 'COVERAGE_DOES_NOT_MATCH'],
  personalPropertyCoverage: ['PERSONAL_PROPERTY', 'COVERAGE_DOES_NOT_MATCH'],
  medicalPayments: ['MEDICAL_PAYMENTS'],
} as const;
