export enum RiApplicationPolicyStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export enum RiApplicationComplianceStatusEnum {
  Compliant = 'compliant',
  NotCompliant = 'not-compliant',
  NeedsReview = 'needs-review',
  InProgress = 'in-progress',
}

export const RiApplicationComplianceStatusToTagMap = {
  compliant: {
    name: 'Compliant',
    color: '#3DB157',
    borderColor: '#3DB157',
  },
  'not-compliant': {
    name: 'Not compliant',
    color: '#FF3030',
    borderColor: '#FF3030',
  },
  'in-progress': {
    name: 'In progress',
    color: 'transparent',
    borderColor: '#121768',
  },
  'needs-review': {
    name: 'In review',
    color: '#121768',
    borderColor: '#121768',
  },
} as const;
