import { MuiSxCollection } from '../../types';
import { ParsedRiApplicationAddress } from '../../utils';
import Box from '@mui/material/Box';
import AddressCardImage from '@/assets/images/RentersInsuranceAddressCard.png';
import palette from '@/theme/themePalette';
import { ErrorOutline } from '@mui/icons-material';
import { RiApplicationDetailsAddressValue } from './RiApplicationDetailsAddressValue';

const addressStyles = {
  address: {
    boxSizing: 'border-box',
    height: '139px',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    borderColor: '#080A2D4D',
  },
  addressName: {
    color: '#595959',
  },
  addressContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 'inherit',
    padding: '16px',
    borderLeft: '1px solid #080A2D4D',
    borderBottom: '1px solid #080A2D4D',
    borderTop: '1px solid #080A2D4D',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  addressCard: {
    width: '100%',
    objectFit: 'cover',
    height: '139px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  error: {
    color: palette.error.main,
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    color: palette.error.main,
  },
  errorIcon: {
    color: palette.error.main,
    width: '18px',
    height: '18px',
  },
} satisfies MuiSxCollection;

interface RiApplicationDetailsAddressProps {
  address: ParsedRiApplicationAddress;
  buildingUnit: string;
  isLoading: boolean;
  isReasonError: boolean;
}

export function RiApplicationDetailsAddress({
  address,
  isLoading,
  buildingUnit,
  isReasonError,
}: RiApplicationDetailsAddressProps) {
  const displayErrorIcon = isReasonError && !isLoading;
  const trimmedAddressName =
    address.addressName.length > 30
      ? `${address.addressName.slice(0, 30)}...`
      : address.addressName;

  return (
    <Box component={'article'} sx={addressStyles.address}>
      <Box sx={addressStyles.addressContent}>
        {displayErrorIcon ? <ErrorOutline sx={addressStyles.errorIcon} /> : null}
        <RiApplicationDetailsAddressValue
          sx={addressStyles.addressName}
          isLoading={isLoading}
          isReasonError={isReasonError}
          skeletonProps={{ width: 100, height: 24 }}
        >
          {trimmedAddressName}
        </RiApplicationDetailsAddressValue>
        <RiApplicationDetailsAddressValue
          isLoading={isLoading}
          isReasonError={isReasonError}
          skeletonProps={{ width: 150, height: 24 }}
        >
          {address.displayedLine1}
        </RiApplicationDetailsAddressValue>
        <RiApplicationDetailsAddressValue
          isLoading={isLoading}
          isReasonError={isReasonError}
          skeletonProps={{ width: 150, height: 24 }}
        >
          {address.displayedLine2}
        </RiApplicationDetailsAddressValue>
        <RiApplicationDetailsAddressValue
          isLoading={isLoading}
          isReasonError={isReasonError}
          skeletonProps={{ width: 80, height: 24 }}
        >
          {buildingUnit}
        </RiApplicationDetailsAddressValue>
      </Box>
      <Box sx={addressStyles.addressCard} component={'img'} loading="lazy" src={AddressCardImage} />
    </Box>
  );
}
