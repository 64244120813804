import { Grid, Stack, Typography, useTheme, Box, useMediaQuery } from '@mui/material';
import useFeatureFlagStore from '../stores/featureflagStore';
import { Link } from '../components/Link';
import { footerMenu, externalLinks } from '../constants/Constants';

const SiteFooter = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { featureFlags } = useFeatureFlagStore();

  const currentYear = new Date().getFullYear();
  const footerMenuLinks = featureFlags.enable_footer_trust_center
    ? footerMenu
    : footerMenu.filter((item) => item.to !== externalLinks.trustCenter);

  const CopyrightText = () => (
    <Typography mt={2} color="text.secondary" variant="helpertext" sx={{ opacity: '.5' }}>
      © {currentYear} TheGuarantors Inc.
    </Typography>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'left', lg: 'flex-end' }}
        spacing={4}
        sx={{ pt: { xs: 15, sm: 15, lg: 5 } }}
      >
        <div style={{ maxWidth: '620px' }}>
          <Typography variant="helpertext" sx={{ opacity: '.5' }}>
            TheGuarantors is a licensed insurance agent and broker. For more detailed information,
            please see our{' '}
            <Link
              underline="always"
              data-variant="noColor"
              target="_blank"
              color={'text.secondary'}
              href={externalLinks.legalRegulatory}
              sx={{ textDecorationColor: 'grey' }}
            >
              Legal/Regulatory Notice
            </Link>{' '}
            .
          </Typography>
        </div>
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            direction="row"
            alignItems={{ xs: 'none', lg: 'flex-end' }}
            justifyContent={{ xs: 'space-between', lg: 'flex-end' }}
            columnSpacing={{ xs: 2, md: 2, lg: 4 }}
            rowSpacing={{ xs: 2, lg: 0 }}
            width={'100%'}
            minHeight="50px"
          >
            {isDesktop && <CopyrightText />}
            {footerMenuLinks.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      textAlign: !(index % 2) ? 'left' : 'right',
                    },
                  }}
                  xs={6}
                  lg="auto"
                >
                  <Link
                    variant="helpertext"
                    sx={{ opacity: '.5' }}
                    color="text.secondary"
                    target="_blank"
                    href={item.to}
                    underline="hover"
                    role="footerLinks"
                  >
                    {item.title}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Stack>
      <Box pb={10} pt={2}>
        {!isDesktop && <CopyrightText />}
      </Box>
    </Box>
  );
};

export default SiteFooter;
