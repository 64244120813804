import { FC, ReactNode, useState } from 'react';
import { Toast, ToastStyle } from './Toast';
import { ToastContext, ToastMessage } from './useToast';

export const ToastProvider: FC<{ children: ReactNode } & ToastStyle> = ({ children, ...props }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const removeMessage = (key: number) => setMessages((arr) => arr.filter((m) => m.key !== key));

  return (
    <ToastContext.Provider
      value={{
        addMessage(message) {
          setMessages((arr) => [...arr, message]);
        },
      }}
    >
      {children}
      {messages.map((m) => (
        <Toast key={m.key} message={m} onExited={() => removeMessage(m.key)} {...props} />
      ))}
    </ToastContext.Provider>
  );
};
