import { apiUri } from '@/constants/Constants';
import { TimeDuration } from '../constants';
import { RiDetailedApplication } from '../types';
import { client } from '@/utils/axios/axiosClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export interface GetRiApplicationResponse {
  result: RiDetailedApplication;
}

export async function getRiApplication(applicationId: string): Promise<GetRiApplicationResponse> {
  const requestUrl = apiUri.getRiApplication(applicationId);
  const response = await client.get(requestUrl, {
    withCredentials: true,
  });

  return response.data;
}

export function useRiApplication({
  applicationId,
  enabled,
}: {
  applicationId: string;
  enabled: boolean;
}) {
  const cacheKey = ['riApplication', applicationId];

  const data = useQuery({
    queryKey: cacheKey,
    queryFn: () => getRiApplication(applicationId),
    placeholderData: keepPreviousData,
    enabled,
    staleTime: TimeDuration.Minute * 2,
    refetchOnWindowFocus: false,
  });

  return data;
}
