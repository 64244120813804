type ToCurrencyConfig = Pick<
  Intl.NumberFormatOptions,
  'maximumFractionDigits' | 'minimumFractionDigits'
>;

/**
 * Converts a string value to a formatted currency string.
 * Removes decimal places if the value is a whole number (e.g. 1000.00 -> $1,000).
 *
 * By default, the function will format the value as a currency with a minimum of 2
 * and a maximum of 2 decimal places.
 *
 *
 * @example
 * toCurrency(1000) // $1,000 (no decimal places)
 * toCurrency(1000.5) // $1,000.50 (2 decimal places)
 *
 * @param value - The value to be converted to currency.
 * @param config - Optional configuration for formatting the currency.
 * @returns The formatted currency string.
 */
export function toCurrency(value: string | number, config?: ToCurrencyConfig): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...config,
  })
    .format(Number(value))
    .replace(/\.00$/, '');
}
