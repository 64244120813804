import { Box, CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <Box
      id="loader-box"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};
export default Loader;
