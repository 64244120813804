import Badge from '@mui/material/Badge';
import { MuiSxCollection, Except } from '../../types';
import { ComponentPropsWithoutRef } from 'react';
import { mergeSx } from '../../utils';

const riNewBadgeStyles = {
  badge: {
    position: 'static',
    transform: 'none',
    '& .MuiBadge-badge': {
      position: 'static',
      transform: 'none',
      backgroundColor: '#C5E8CD',
      borderRadius: '16px',
      paddingX: '10px',
      paddingY: '2px',
      lineHeight: '18px',
      height: 'auto',
      fontSize: '12px',
    },
  },
} satisfies MuiSxCollection;

type RiNewBadgeProps = Except<ComponentPropsWithoutRef<typeof Badge>, 'badgeContent' | 'children'>;

export function RiNewBadge({ sx, ...props }: RiNewBadgeProps) {
  return <Badge badgeContent={'New'} sx={mergeSx(riNewBadgeStyles.badge, sx)} {...props} />;
}
