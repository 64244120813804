export const defaultFeedbackOptions = [
  { label: '51-200', value: '51-200', id: 3 },
  { label: '201-3,000', value: '201-3,000', id: 4 },
  { label: '3,001-10,000', value: '3,001-10,000', id: 5 },
  { label: '10,001-30,000', value: '10,001-30,000', id: 6 },
  { label: '30,001+', value: '30,001+', id: 7 },
] as const;

export const oldFeedbackOptions = [
  { label: '1-50', value: '1-50', id: 1 },
  ...defaultFeedbackOptions,
] as const;

export const newFeedbackOptions = [
  { label: '1-10', value: '1-10', id: 1 },
  { label: '11-50', value: '11-50', id: 2 },
  ...defaultFeedbackOptions,
] as const;

export enum sizeOfPortfolio {
  oneToFifty = '1-50',
  oneToTen = '1-10',
  elevenToFifty = '11-50',
  fiftyOneToTwoHundred = '51-200',
  TwoHundredOneToThreeThousand = '201-3,000',
  ThreeThousandOneTotenThousand = '3,001-10,000',
  TenThousandOneToThirtyThousand = '10,001-30,000',
  ThirtyThousandOneAndMore = '30,001+',
}
export const basicOptions = [{ label: '1-50', value: '1-50', id: 1 }] as const;

export enum sizeOfPortfolioBasic {
  oneToFifty = '1-50',
}

//use for alan data to store in hubspot with correct size range.
export const getCorrectRangeForSizeOfPortfolio = (number: number) => {
  let result = '';
  switch (true) {
    case number >= 0 && number <= 10:
      result = sizeOfPortfolio.oneToTen;
      break;
    case number >= 11 && number <= 50:
      result = sizeOfPortfolio.elevenToFifty;
      break;

    case number >= 51 && number <= 200:
      result = sizeOfPortfolio.fiftyOneToTwoHundred;
      break;
    case number >= 201 && number <= 3000:
      result = sizeOfPortfolio.TwoHundredOneToThreeThousand;
      break;
    case number >= 3001 && number <= 10000:
      result = sizeOfPortfolio.ThreeThousandOneTotenThousand;
      break;
    case number >= 10001 && number <= 30000:
      result = sizeOfPortfolio.TenThousandOneToThirtyThousand;
      break;
    case number >= 30001:
      result = sizeOfPortfolio.ThirtyThousandOneAndMore;
      break;
  }
  return result;
};
