import React from 'react';

function SvgComponent({ width = 30, height = 30, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 10.277c0 4.971 3.61 9.106 8.333 9.944v-7.223h-2.5v-2.777h2.5V7.998c0-2.5 1.611-3.888 3.89-3.888.721 0 1.5.11 2.221.222v2.555h-1.277c-1.223 0-1.5.611-1.5 1.39v1.944h2.666l-.444 2.777h-2.222v7.223C16.389 19.383 20 15.249 20 10.277 20 4.746 15.5.22 10 .22S0 4.746 0 10.277Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export const FacebookIcon = React.memo(SvgComponent);

FacebookIcon.displayName = 'FacebookIcon';

export default FacebookIcon;
