import { trackEvent } from './analytics';

export function trackOnClick(event: any) {
  if (!event.target) return;
  if (event.target?.nodeName !== 'INPUT' && event.target?.nodeName !== 'BUTTON') return;

  const cleanedUrlPath =
    location && location.pathname
      ? location.pathname
          .replace(/\/|-/g, ' ')
          .trim()
          .split(' ')
          .map((word) => {
            return word && word.length > 0 ? word[0].toUpperCase() + word.substring(1) : '';
          })
          .join(' ')
      : '';

  if (event.target?.nodeName === 'INPUT') {
    trackEvent(`${event.target.name} Input on ${cleanedUrlPath + location?.hash} Form`, {
      input: event.target.name,
      value: event.target.value,
    });
  } else if (event.target?.nodeName === 'BUTTON') {
    trackEvent(`${event.target.innerText} Button clicked on ${cleanedUrlPath + location?.hash}`, {
      input: event.target.innerText,
    });
  }
}
