export const RiSegmentEvents = {
  RiApplicationsTableRowsPerPageChange: 'RI Dashboard - Rows Per Page change',
  RiApplicationTableSearchBarChange: 'RI Dashboard - Search Bar change',
  RiApplicationSortOrderChange: 'RI Dashboard - Sort order change',
  RiApplicationDrawerClick: 'RI Dashboard - Application Drawer click',
  RiApplicationExportOptionClick: 'RI Dashboard - Export click',
  RiApplicationTableFilterClick: 'RI Dashboard - Filter click',
  RiApplicationNextPageClick: 'RI Dashboard - Next table page click',
  RiApplicationPreviousPageClick: 'RI Dashboard - Previous table page click',
} as const;
