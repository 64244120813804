import { useEffect, useRef } from 'react';
import useScript from '../utils/useScript';
import { useQuery } from '@tanstack/react-query';
import getUser from '../utils/apiRestCalls/getUser';
import { trackEvent } from '../utils/analytics';

export default function MyLandbot() {
  const containerRef = useRef(null);
  const url = 'https://storage.googleapis.com/landbot.pro/v3/H-1691319-ISQL175QF7S7V8EA/index.json';
  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    staleTime: Infinity,
  });

  const onLoad = () => {
    new (window as any).Landbot.Livechat({
      container: containerRef.current,
      configUrl: url,
      customData: {
        user_email: user?.email,
        user_first_name: user?.firstName,
        anon_user_id: localStorage.getItem('ajs_anonymous_id'),
      },
    });
  };

  function botOpen() {
    if (document.getElementsByClassName('is-open')[0]) {
      trackEvent(`LLM Chatbot Opened`, {
        referrer: window.location.pathname,
        anon_user_id: localStorage.getItem('ajs_anonymous_id'),
        bot: 'propertymanager',
      });
    } else {
      setTimeout(botOpen, 2000);
    }
  }

  useEffect(() => {
    botOpen();
  }, []);

  useScript('https://cdn.landbot.io/landbot-3/landbot-3.0.0.js', onLoad);

  return <div className="MyLandbot" ref={containerRef} />;
}
