import { ComponentPropsWithoutRef, forwardRef } from 'react';

export const RiTableErrorIcon = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<'svg'>>(
  (props, ref) => (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9723 11.5879C21.7455 11.5879 22.3723 12.2147 22.3723 12.9879V22.9639C22.3723 23.7371 21.7455 24.3639 20.9723 24.3639C20.1991 24.3639 19.5723 23.7371 19.5723 22.9639V12.9879C19.5723 12.2147 20.1991 11.5879 20.9723 11.5879Z"
        fill="#595959"
      />
      <path
        d="M21 29.0078C21.8174 29.0078 22.48 28.3452 22.48 27.5279C22.48 26.7105 21.8174 26.0479 21 26.0479C20.1826 26.0479 19.52 26.7105 19.52 27.5279C19.52 28.3452 20.1826 29.0078 21 29.0078Z"
        fill="#595959"
      />
      <path
        d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z"
        stroke="#595959"
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
