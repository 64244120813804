import { createColumnHelper } from '@tanstack/table-core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ComplianceStatusTag } from '../';
import {
  RI_APPLICATION_TABLE_FALLBACK_VALUE,
  DateFormat,
  RiApplicationTableColumnIds,
} from '../../constants';
import { mapRiApplicationSourceToDisplayedValue, RiApplicationsTableColumnMeta } from '../../utils';
import { time } from '../../libs';
import { riPalette } from '@/theme/themePalette';
import { MuiSxCollection, RiListApplication } from '../../types';

const columnHelper = createColumnHelper<RiListApplication>();

const riApplicationsTableColumnsStyles = {
  buildingNameCell: {
    display: 'block',
    wordBreak: 'break-word',
  },
  renterCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  renterCellName: {
    lineHeight: 'inherit',
    wordBreak: 'break-word',
  },
  renterCellEmail: {
    color: riPalette.text?.secondary,
    fontSize: '12px',
    lineHeight: '16px',
    wordBreak: 'break-word',
  },
  linkTypeCell: {
    textTransform: 'capitalize',
  },
} satisfies MuiSxCollection;

export const riApplicationsTableColumns = [
  columnHelper.accessor('address.addressName', {
    id: RiApplicationTableColumnIds.BuildingName,
    header: 'Building name',
    enableSorting: false,
    cell: (row) => {
      const rowValue = row.cell.getValue() ?? RI_APPLICATION_TABLE_FALLBACK_VALUE;

      return (
        <Typography component={'span'} sx={riApplicationsTableColumnsStyles.buildingNameCell}>
          {rowValue}
        </Typography>
      );
    },
    meta: {
      headerSx(theme) {
        const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

        return {
          width: '25%',
          [minFixedWidthBreakpoint]: {
            width: 280,
          },
        };
      },
    } satisfies RiApplicationsTableColumnMeta,
    size: undefined,
  }),
  columnHelper.accessor('buildingUnit', {
    id: RiApplicationTableColumnIds.BuildingUnit,
    header: 'Unit',
    enableSorting: false,
    cell: (row) => {
      const rowValue = row.cell.getValue() ?? RI_APPLICATION_TABLE_FALLBACK_VALUE;

      return <Typography component={'span'}>{rowValue}</Typography>;
    },
    meta: {
      headerSx(theme) {
        const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

        return {
          width: '8%',
          [minFixedWidthBreakpoint]: {
            width: 55,
          },
        };
      },
    } satisfies RiApplicationsTableColumnMeta,
    size: undefined,
  }),
  columnHelper.accessor(
    (row) => {
      return {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
      };
    },
    {
      id: RiApplicationTableColumnIds.Renter,
      header: 'Renter',
      enableSorting: true,
      cell: (row) => {
        return (
          <Box sx={riApplicationsTableColumnsStyles.renterCell}>
            <Typography component={'span'} sx={riApplicationsTableColumnsStyles.renterCellName}>
              {row.cell.getValue().firstName} {row.cell.getValue().lastName}
            </Typography>
            <Typography component={'span'} sx={riApplicationsTableColumnsStyles.renterCellEmail}>
              {row.cell.getValue().email}
            </Typography>
          </Box>
        );
      },
      meta: {
        cellSx: {
          padding: {
            paddingTop: '8px',
            paddingBottom: '10px',
            paddingLeft: '16px',
            paddingRight: '24px',
          },
        },
        headerSx: (theme) => {
          const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

          return {
            width: '23%',
            [minFixedWidthBreakpoint]: {
              width: 250,
            },
          };
        },
      } satisfies RiApplicationsTableColumnMeta,
      size: undefined,
    },
  ),
  columnHelper.accessor('source', {
    id: RiApplicationTableColumnIds.Source,
    header: 'Policy type',
    enableSorting: true,
    cell: (row) => {
      return mapRiApplicationSourceToDisplayedValue(row.cell.getValue());
    },
    meta: {
      headerSx(theme) {
        const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

        return {
          width: '14%',
          [minFixedWidthBreakpoint]: {
            width: 160,
          },
        };
      },
    } satisfies RiApplicationsTableColumnMeta,
  }),
  columnHelper.accessor('updatedAt', {
    id: RiApplicationTableColumnIds.UpdatedAt,
    header: 'Last updated',
    enableSorting: true,
    cell: (row) => {
      return time(row.cell.getValue(), DateFormat.Timestamp).format(DateFormat.Us);
    },
    meta: {
      headerSx(theme) {
        const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

        return {
          width: '13%',
          [minFixedWidthBreakpoint]: {
            width: 140,
          },
        };
      },
    } satisfies RiApplicationsTableColumnMeta,
  }),
  columnHelper.accessor(
    (row) => {
      return {
        complianceStatus: row.complianceStatus,
        reasonsNotCompliant: row.reasonsNotCompliant,
      };
    },
    {
      id: RiApplicationTableColumnIds.ComplianceStatus,
      header: 'Status',
      enableSorting: true,
      cell: (row) => {
        const { complianceStatus, reasonsNotCompliant } = row.cell.getValue();

        return <ComplianceStatusTag value={complianceStatus} reasons={reasonsNotCompliant} />;
      },
      meta: {
        headerSx(theme) {
          const minFixedWidthBreakpoint = theme.breakpoints.down(1281);

          return {
            width: '17%',
            [minFixedWidthBreakpoint]: {
              width: 163,
            },
          };
        },
      } satisfies RiApplicationsTableColumnMeta,
      size: undefined,
    },
  ),
];
