import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { sizeOfPortfolio } from './constants/UnitOptions';

const stepVariant = {
  1: 'units',
  2: 'register',
};
export type StateUSA = {
  id: number;
  label: string;
  value: string;
  isEastCoast?: boolean;
};

export type UserFullDTO = {
  data: { data: UserFull };
  status: number;
};

type UserFull = UnitsData | RegisterData | Extra;

type Extra = {
  redirectUri: string;
  id: string;
};

export type UnitsData = {
  sizeOfPortfolio: sizeOfPortfolio | undefined;
  ownedUnits?: number;
  state: StateUSA | undefined;
};

export type RegisterData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  propertyLLC?: string;
};

type setDataType = { step: 1; data: UnitsData } | { step: 2; data: RegisterData };

const useFormStore = create(
  devtools<{
    units: UnitsData | null;
    register: RegisterData | null;
    setData: ({ step, data }: setDataType) => void;
  }>((set) => ({
    units: {
      sizeOfPortfolio: undefined,
      state: {
        id: 0,
        label: '',
        value: '',
      },
    },
    register: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      propertyLLC: '',
      agree: true,
    },
    setData: ({ step, data }) =>
      set((state) => ({
        ...state,
        [stepVariant[step]]: data,
      })),
  })),
);

export default useFormStore;
