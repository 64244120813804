import { Maybe } from 'yup';

export function toInteger(value: Maybe<string>, config: { fallbackValue: number }): number;
export function toInteger(value: Maybe<string>, config: { fallbackValue?: number }): number | null;
export function toInteger(value: Maybe<string>, config?: { fallbackValue?: number }): number | null;
/**
 * Converts a string value to an integer.
 *
 * @param value - The string value to convert.
 * @param config - Optional configuration object.
 * @param config.fallbackValue - The fallback value to return if the conversion fails. Defaults to null.
 * @returns The converted integer value or the fallback value if the conversion fails.
 */
export function toInteger(
  value: Maybe<string>,
  config?: { fallbackValue?: number },
): number | null {
  if (!value) {
    return config?.fallbackValue ?? null;
  }
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return config?.fallbackValue ?? null;
  }

  return parsedValue;
}
