import { apiUri } from '../../constants/Constants';
import { client } from '../axios/axiosClient';

export interface UserDataToUpdate {
  firstName?: string;
  lastName?: string;
  phone?: string;
  propertyLLC?: string;
  totalUnitsOwned?: number;
  sizeOfPortfolio?: string;
}

const postUser = async (userInfo: UserDataToUpdate) => {
  return await client.patch(apiUri.updateUserInfo, userInfo);
};

export default postUser;
