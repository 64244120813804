import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import TheGuarantorsLogo from '../assets/images/updated-logo.png';
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUrlChecker } from '../hooks/useUrlChecker';
import { externalConnections, externalLinks, routes } from '../constants/Constants';
import { MobileMenu } from './Menu/MobileMenu';
import { goToExternal } from '../utils/linkUtils';
import useProgressStore from '../stores/progressStore';
import { Link } from '../components/Link';

function SiteHeader() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isSigningUp, isPublic } = useUrlChecker();
  const preRegister = isPublic || window.location.pathname === routes.unitsOwnedEnroll;

  const { progress } = useProgressStore();

  return (
    <Box sx={{ minHeight: { xs: '100px', sm: '148px' } }}>
      <LinearProgress
        sx={{ display: progress ? 'fixed' : 'none', position: 'fixed' }}
        value={progress}
      />
      <AppBar>
        <Toolbar>
          {isMobile && !preRegister ? <MobileMenu /> : null}
          {isSigningUp ? (
            <>
              <IconButton
                sx={{ border: '1px solid black', mr: 2 }}
                onClick={() => navigate(-1)}
                id="backButton"
              >
                <ArrowBackIcon sx={{ color: 'black', fontSize: { xs: '12px', md: '22px' } }} />
              </IconButton>
              <Typography
                onClick={() => navigate(-1)}
                sx={{ display: { xs: 'none', sm: 'flex' }, cursor: 'pointer' }}
              >
                Back
              </Typography>
            </>
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          <img
            onClick={() => {
              goToExternal(externalLinks.homePage, {
                title: 'Go to Main Page',
                path: '/home',
              });
            }}
            style={{ cursor: 'pointer' }}
            height={isMobile ? '20px' : '24px'}
            src={TheGuarantorsLogo}
            alt="TheGuarantors"
          />
          <Box sx={{ flexGrow: 1 }} />
          {isSigningUp ? (
            <Link data-variant="navItem" underline="none" href={externalConnections.signin}>
              Sign In
            </Link>
          ) : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SiteHeader;
