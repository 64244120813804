import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { Skeleton, SxProps } from '@mui/material';
import palette from '@/theme/themePalette';
import { ErrorOutline } from '@mui/icons-material';
import { AppTheme, MuiSxCollection } from '../../types';
import { mergeSx } from '../../utils';

const detailsFieldStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#595959',
    width: '50%',
  },
  error: {
    color: palette.error.main,
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  errorIcon: {
    width: '18px',
    height: '18px',
  },
  value: {
    fontSize: '14px',
    lineHeight: '24px',
    width: '50%',
    wordBreak: 'break-word',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
} satisfies MuiSxCollection;

interface RiApplicationDetailsFieldProps {
  label: ReactNode;
  value: ReactNode;
  /** Boolean or string indicating if the data is loading. If it is 'all', it will show the loading skeleton for both label and value */
  isLoading?: boolean | 'all';
  /** Boolean indicating if there was an error while fetching the data. For example using a useQuery hook */
  isFetchError?: boolean;
  /** Boolean to apply the error styles */
  isReasonError?: boolean;
  /** Field to apply the error styles. By default, it applies to the value */
  reasonErrorField?: 'label' | 'value';
  /** Props to pass to the loading skeleton */
  skeletonProps?: ComponentPropsWithoutRef<typeof Skeleton>;
  sx?: SxProps<AppTheme>;
}

export function RiApplicationDetailsField({
  label,
  value,
  isLoading,
  isFetchError,
  isReasonError,
  reasonErrorField = 'value',
  skeletonProps,
  sx,
}: RiApplicationDetailsFieldProps) {
  const displayedValue = isFetchError ? '-' : value;

  const isValueLoading = isLoading === true || isLoading === 'all';
  const isLabelLoading = isLoading === 'all';

  const hasLabelReasonError = isReasonError && reasonErrorField === 'label';
  const hasValueReasonError = isReasonError && reasonErrorField === 'value';

  const combinedLabelSx = mergeSx(
    detailsFieldStyles.label,
    hasLabelReasonError && detailsFieldStyles.error,
  );
  const combinedValueSx = mergeSx(
    detailsFieldStyles.value,
    hasValueReasonError && detailsFieldStyles.error,
  );

  return (
    <Box sx={mergeSx(detailsFieldStyles.root, sx)}>
      <Typography sx={combinedLabelSx}>
        {isLabelLoading ? (
          <Skeleton {...skeletonProps} />
        ) : hasLabelReasonError ? (
          <Box sx={detailsFieldStyles.errorWrapper} component={'span'}>
            <ErrorOutline sx={detailsFieldStyles.errorIcon} />
            {label}
          </Box>
        ) : (
          label
        )}
      </Typography>
      <Typography sx={combinedValueSx}>
        {isValueLoading ? (
          <Skeleton {...skeletonProps} />
        ) : hasValueReasonError ? (
          <Box sx={detailsFieldStyles.errorWrapper} component={'span'}>
            <ErrorOutline sx={detailsFieldStyles.errorIcon} />
            {displayedValue}
          </Box>
        ) : (
          displayedValue
        )}
      </Typography>
    </Box>
  );
}
