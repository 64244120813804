import { RiApplicationDetailsDrawer } from '../RiApplicationDetailsDrawer';
import { RiTableRow, RiTableCell } from '../RiTable';
import { MuiSxCollection, RiListApplication } from '../../types';
import { getRiApplicationsTableColumnMeta, mergeSx } from '../../utils';
import { flexRender, Row } from '@tanstack/react-table';
import { Fragment, useState } from 'react';

interface RiApplicationsTableRowProps {
  row: Row<RiListApplication>;
}

const rowStyles = {
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#3DB15714',
    },
  },
  rowActive: {
    backgroundColor: '#080A2D14',
  },
} satisfies MuiSxCollection;

export function RiApplicationsTableRow({ row }: RiApplicationsTableRowProps) {
  const [isOpen, setIsOpen] = useState(false);
  const applicationId = row.original.id;

  function handleDrawerOpen() {
    setIsOpen(true);
  }

  function handleDrawerClose() {
    setIsOpen(false);
  }

  return (
    <Fragment>
      <RiTableRow
        key={row.id}
        onClick={handleDrawerOpen}
        sx={mergeSx(rowStyles.row, isOpen && rowStyles.rowActive)}
      >
        {row.getVisibleCells().map((cell) => {
          const columnMeta = getRiApplicationsTableColumnMeta(cell);

          return (
            <RiTableCell key={cell.id} sx={columnMeta.cellSx}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </RiTableCell>
          );
        })}
      </RiTableRow>
      <RiApplicationDetailsDrawer
        isOpen={isOpen}
        onClose={handleDrawerClose}
        applicationId={applicationId}
      />
    </Fragment>
  );
}
