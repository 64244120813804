import getEnv from '../../env';
import { createAxiosClient } from './createAxiosClient';

export const client = createAxiosClient({
  options: {
    baseURL: getEnv().backend,
    timeout: 300000,
    headers: {
      'Content-Type': 'application/json',
    },
  },
});
