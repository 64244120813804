import { RiTableErrorIcon } from './RiTableErrorIcon';
import { MuiSxCollection } from '../../types';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Fragment, ReactNode } from 'react';

const errorTableStyles = {
  row: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cell: {
    paddingY: '60px',
    paddingX: '0',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  contentTitle: {
    color: '#595959',
    lineHeight: '28px',
  },
  contentText: {
    color: '#595959',
    lineHeight: '26px',
  },
} satisfies MuiSxCollection;

interface RiTableErrorProps {
  errorMessage?: ReactNode;
}

export function RiTableError({ errorMessage }: RiTableErrorProps) {
  return (
    <TableRow sx={errorTableStyles.row}>
      <TableCell colSpan={1000} sx={errorTableStyles.cell}>
        <Box sx={errorTableStyles.box}>
          <RiTableErrorIcon />
          <Box sx={errorTableStyles.content}>
            <Typography component="h3" variant="h6" sx={errorTableStyles.contentTitle}>
              An error occurred.
            </Typography>
            <Typography sx={errorTableStyles.contentText}>
              {errorMessage || (
                <Fragment>
                  {
                    'Refresh the page and try again. If this problem persists, reach out to our team at'
                  }{' '}
                  <Link underline="none" href="mailto:ri.pm@theguarantors.com">
                    ri.pm@theguarantors.com
                  </Link>
                </Fragment>
              )}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
