import { sizeOfPortfolio } from '../features/Registration/constants/UnitOptions';
import featureflagStore from '../stores/featureflagStore';

export const isUserSMB = (selectedSizeOfPortfolio: sizeOfPortfolio | undefined) => {
  const latestFeatureFlag = featureflagStore.getState().featureFlags;

  if (
    selectedSizeOfPortfolio &&
    [
      sizeOfPortfolio.oneToFifty,
      sizeOfPortfolio.oneToTen,
      sizeOfPortfolio.elevenToFifty,
      sizeOfPortfolio.fiftyOneToTwoHundred,
    ].includes(selectedSizeOfPortfolio) &&
    latestFeatureFlag?.is_new_smb_portfolio_amount
  ) {
    return true;
  } else if (
    selectedSizeOfPortfolio &&
    [sizeOfPortfolio.oneToFifty, sizeOfPortfolio.oneToTen, sizeOfPortfolio.elevenToFifty].includes(
      selectedSizeOfPortfolio,
    ) &&
    !latestFeatureFlag?.is_new_smb_portfolio_amount
  ) {
    return true;
  } else {
    return false;
  }
};
