import { MuiSxCollection } from '../../types';
import { getReasonsNotCompliantMessage } from '../../utils';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

interface ReasonsNotCompliantIconProps {
  reasons: string[];
}

const reasonsNotCompliantIconStyles = {
  tooltip: {
    whiteSpace: 'pre-line',
    width: '100%',
    fontSize: '10px',
    lineHeight: '14px',
    paddingY: '4px',
    paddingX: '8px',
    position: 'relative',
    '& .MuiTooltip-arrow': {
      left: '50%',
      transform: 'translateX(-50%)',
      '&::before': {
        transform: 'rotate(45deg)',
      },
    },
  },
} satisfies MuiSxCollection;

export function ReasonsNotCompliantIcon({ reasons }: ReasonsNotCompliantIconProps) {
  const tooltipMessage = getReasonsNotCompliantMessage(reasons).join('\n');

  return (
    <Tooltip
      title={tooltipMessage}
      arrow={true}
      placement="top-end"
      sx={{
        '& .MuiTooltip-tooltip': reasonsNotCompliantIconStyles.tooltip,
      }}
    >
      <InfoOutlined
        data-testid="reasons-not-compliant-icon"
        sx={{
          width: '20px',
          height: '20px',
        }}
      />
    </Tooltip>
  );
}
