export enum RiApplicationSourceEnum {
  Msi = 'MSI',
  Covie = 'COVIE',
}

export enum RiApplicationSourceQueryParamsEnum {
  TheGuarantors = 'theGuarantors',
  ThirdParty = 'third party',
}

export enum DisplayedRiApplicationSourceEnum {
  TheGuarantors = 'TheGuarantors',
  ThirdParty = 'Third party',
}
