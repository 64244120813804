export enum Target {
  blank = '_blank',
  self = '_self',
  parent = '_parent',
  top = '_top',
}

export interface SegmentObj {
  title: string;
  path: string;
}
