import React from 'react';
import { Menu } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilterDropdownItem } from './FilterDropdownItem';
import { styled } from '@mui/material/styles';
import { DataTestId } from '../../../types';

type Option = {
  title: string;
  name: string;
  selected: boolean;
};

type Props = {
  title: string;
  options: Option[];
  onOpen: () => void;
  onOptionClick: (optionValue: string) => void;
  onBlur: () => void;
} & DataTestId;

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: 'transparent',
  outline: 'none',
  border: 'none',
  padding: '8px',
  textTransform: 'none',
  '&:hover': {
    background: 'none',
    'span:first-of-type': {
      display: 'inline-block',
      position: 'relative',
      '&:after': {
        content: '""',
        background: theme.palette.text.primary,
        transition: 'background 0.25s ease',
        height: '1px',
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-3px',
      },
    },
  },
}));

export function FilterDropdown({ title, options, onOpen, onOptionClick, onBlur, ...rest }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onBlur();
  };

  const buttonId = `${title}-filter-button-to-open-menu`;
  const menuId = `${title}-filter-menu`;
  return (
    <>
      <ButtonStyled
        id={buttonId}
        data-testid={`${rest['data-testid']}-btn`}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple={true}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <span>{title}</span>
      </ButtonStyled>
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid={`${rest['data-testid']}-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(({ title, name, selected }) => (
          <FilterDropdownItem key={name} isChecked={selected} onClick={() => onOptionClick(name)}>
            {title}
          </FilterDropdownItem>
        ))}
      </Menu>
    </>
  );
}
