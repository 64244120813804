import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { loadRiPermissionsFromStorage } from '@/utils/storage/ri-permissions.storage';
import { AllValues } from '@/utils/types/types.util';
import { RiPermissions } from '@/constants/ri-permissions.constant';

const useRiPermissionStore = create(
  devtools<{
    riPermissions: AllValues<typeof RiPermissions>[];
    setRiPermissions: (riPermissions: AllValues<typeof RiPermissions>[]) => void;
  }>((set) => ({
    riPermissions: loadRiPermissionsFromStorage(),
    setRiPermissions: (riPermissions) => {
      set({ riPermissions });
    },
  })),
);

export default useRiPermissionStore;
