import { AppTheme } from '../types';
import { SxProps, Theme } from '@mui/material';

type SxStyle = SxProps<AppTheme | Theme> | undefined | false;

type PurgedSxStyle = Exclude<SxProps<AppTheme | Theme>, ReadonlyArray<unknown>>;

/**
 * Merges multiple MUI sx styles into a single array of sx styles.
 * Use this function to merge multiple sx styles into a single array of sx styles.
 * It can handle sx styles that are functions too.
 *
 * @example
 * ```tsx
 * const sx1 = {
 *   color: 'red',
 * };
 * const sx2 = (theme: AppTheme) => ({
 *   color: theme.palette.primary.main,
 * });
 * const mergedSx = mergeSx(sx1, sx2);
 * ```
 *
 * @param sxStyles - The sx styles to merge.
 * @returns The merged array of SingleSxStyle.
 */
export function mergeSx(...sxStyles: SxStyle[]) {
  const mergedSx: PurgedSxStyle[] = [];

  for (const sx of sxStyles) {
    if (!sx) {
      continue;
    }

    if (Array.isArray(sx)) {
      mergedSx.push(...sx);

      continue;
    }

    mergedSx.push(sx as PurgedSxStyle);
  }

  return mergedSx;
}
