import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import getUser from '../../utils/apiRestCalls/getUser';
import userValues from '../../utils/normalizeUser';
import postUser from '../../utils/apiRestCalls/postUser';
import { capitalizeFirstLetter, formatPhoneNumber } from '../../utils/stringUtils';
import { AxiosUserResponse } from '../../utils/types/User';
import useProfileDrawerStore from '../../stores/useProfileDrawerStore';
import { profileSchema } from '../../utils/validator/userValidator';

interface IFormInputs {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  propertyLLC?: string;
}

const RegistrationProfile = () => {
  const { hideProfileDrawer } = useProfileDrawerStore();

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    staleTime: Infinity,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (userInfo: IFormInputs) => {
      return postUser(userInfo);
    },
    onSuccess: async (response: AxiosUserResponse) => {
      queryClient.setQueryData(['user'], userValues(response));
      hideProfileDrawer();
    },
  });

  const submit = async (data: IFormInputs) => {
    mutation.mutate(data);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<IFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(profileSchema),
  });

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Controller
        name="email"
        control={control}
        defaultValue={user?.email ?? ''}
        render={({ field }) => (
          <TextField
            {...field}
            id="emailAddressId"
            label="Email address"
            error={!!errors.email}
            disabled
            type="email"
            helperText={errors?.email?.message ?? ' '}
            InputProps={{
              classes: {
                root: 'fs-mask',
              },
            }}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        defaultValue={user?.firstName ?? ''}
        render={({ field }) => (
          <TextField
            {...field}
            required
            ref={null}
            id="firstName"
            label="First name"
            error={!!errors.firstName}
            helperText={errors.firstName?.message ?? ' '}
            onChange={(e: { target: { value: string } }) =>
              field.onChange(capitalizeFirstLetter(e.target.value))
            }
            InputProps={{
              classes: {
                root: 'fs-mask',
              },
            }}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        defaultValue={user?.lastName ?? ''}
        render={({ field }) => (
          <TextField
            {...field}
            required
            ref={null}
            id="lastName"
            label="Last name"
            error={!!errors.lastName}
            helperText={errors.lastName?.message ?? ' '}
            onChange={(e: { target: { value: string } }) =>
              field.onChange(capitalizeFirstLetter(e.target.value))
            }
            InputProps={{
              classes: {
                root: 'fs-mask',
              },
            }}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue={user?.phone ?? ''}
        render={({ field }) => (
          <TextField
            {...field}
            label="Phone number"
            ref={null}
            error={!!errors.phone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span>+ 1</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              classes: {
                root: 'fs-mask',
              },
            }}
            id="PhoneNumber"
            helperText={errors?.phone?.message ?? 'Message and data rates may apply'}
            onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))}
          />
        )}
      />
      <Controller
        name="propertyLLC"
        control={control}
        defaultValue={user?.propertyLLC ?? ''}
        render={({ field }) => (
          <TextField
            {...field}
            id="propertyLLCId"
            ref={null}
            label="Property legal entity"
            error={!!errors.propertyLLC}
            disabled={!user?.isAccountAdmin}
            helperText={
              !errors.propertyLLC
                ? 'If there is no legal entity attached to your property, enter your full name.'
                : errors.propertyLLC?.message ?? ' '
            }
          />
        )}
      />
      <Tooltip title={!isValid ? 'Please complete the required fields to proceed' : ''}>
        <span style={{ display: 'inline-block' }}>
          <LoadingButton
            sx={{ mt: { xs: 3, sm: 3.5 } }}
            id="EnrollmentButton"
            variant="contained"
            loading={mutation.isPending}
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Submit
          </LoadingButton>
        </span>
      </Tooltip>
    </form>
  );
};
export default RegistrationProfile;
