import * as yup from 'yup';
import useFormStore from '../../features/Registration/useFormStore';
import { isUserSMB } from '../isUserSMB';
import { sizeOfPortfolio } from '../../features/Registration/constants/UnitOptions';

// Common validation rules
const firstNameValidation = yup
  .string()
  .max(100, 'First name can only have 100 characters')
  .required('First name is required')
  .trim();

const lastNameValidation = yup
  .string()
  .max(100, 'Last name can only have 100 characters')
  .required('Last name is required')
  .trim();

const phoneNumberValidation = yup
  .string()
  .optional()
  .test(
    'valid-number',
    'Enter a valid number',
    (value?: string) => value === '' || value == undefined || /^\(\d{3}\) \d{3}-\d{4}$/.test(value),
  );

const stateValidation = yup
  .object()
  .required('State is required')
  .shape({
    id: yup.number().required(),
    label: yup.string().required(),
    value: yup
      .string()
      .required()
      .test(
        'isNotAlaska',
        "Sorry, we don't support properties in Alaska. Please change your selection if you have properties in another state.",
        (val: string) => val.toLowerCase() !== 'ak',
      ),
  });

const emailValidation = yup
  .string()
  .required('Email is required')
  .email('Not a valid email')
  .trim();

const propertyLLCNotRequired = yup.string().optional().max(100, 'LLC can only have 100 characters');

const sizeOfPortfolioValidation = yup
  .string()
  .required('Size of portfolio is required')
  .oneOf(Object.values(sizeOfPortfolio));

const roleValidator = yup.string().required('User role is required');

const companyValidator = yup
  .string()
  .max(100, 'Company can only have 100 characters')
  .required('Company is required')
  .trim();

// customized validation rules
const propertyLLCValidationWithUnits = yup.string().when([], {
  is: () => !isUserSMB(useFormStore.getState().units?.sizeOfPortfolio),
  then: (schema) =>
    schema
      .max(100, 'Company name can only have 100 characters')
      .required('Company name is required'),
  otherwise: (schema) => schema.optional(),
});

const unitsOwnedValidation = yup.number().when('sizeOfPortfolio', {
  is: (val: string) => val == sizeOfPortfolio.oneToFifty,
  then: (schema) =>
    schema
      .typeError('You must specify a number')
      .required('Number of units is required')
      .test(
        'numUnder50',
        'Number of units must be between 1 and 50',
        (val: number) => val > 0 && +val <= 50,
      ),
  otherwise: (schema) => schema.notRequired().default(undefined),
});

// Schemas
export const brokerSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  propertyLLC: companyValidator,
  email: emailValidation,
  phoneNumber: phoneNumberValidation,
  state: stateValidation,
});

export const profileSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  phone: phoneNumberValidation,
  propertyLLC: propertyLLCNotRequired,
  email: emailValidation,
});

export const unitsOwnedSchema = yup.object().shape({
  sizeOfPortfolio: sizeOfPortfolioValidation,
  state: stateValidation,
});

export const registerAndAgreeschema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  propertyLLC: propertyLLCValidationWithUnits,
  email: emailValidation,
  phoneNumber: phoneNumberValidation,
});

export const UserInvitesSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  role: roleValidator,
});

export const UnitsOwnedOnlySchema = yup.object().shape({
  sizeOfPortfolio: sizeOfPortfolioValidation,
  totalUnitsOwned: unitsOwnedValidation,
});

export const UserEditSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  role: roleValidator,
});
