import featureflagStore from '../stores/featureflagStore';
import { sizeOfPortfolio } from '../features/Registration/constants/UnitOptions';
import { isUserSMB } from './isUserSMB';
import { LLTypes, User } from './types/User';

export type Options = { label: string; value: string };

export const generateYearsOptions = (firstYear: number, lastYear: number): Options[] => {
  const years: Options[] = [];
  for (let i = lastYear; i >= firstYear; i--) {
    years.push({ value: `${i}`, label: `${i}` });
  }
  return years;
};

export const checkIfPmi = (user: { email: string; partner?: string }) => {
  return (
    /@pmi|@propertymanagementinc\.com$|pmi\.com$|pmi@theguarantors.com$/.test(user.email) ||
    user.partner === 'property-management-inc'
  );
};

export const checkIfUserCanEnrollProperties = (user: User) => {
  const latestFeatureFlag = featureflagStore.getState().featureFlags;

  if (latestFeatureFlag.enable_old_size_of_portfolio) {
    const sizeOfPortfolio: sizeOfPortfolio | null = user.sizeOfPortfolioOld;
    return isUserSMB(sizeOfPortfolio) || checkIfPmi(user);
  } else {
    return user.canEnrollProperties;
  }
};

export const shouldDisableRenterInvite = (user?: User) => {
  return user?.status && !user.status.hasProperties;
};

export const showSmbInvite = (user: User) => {
  const latestFeatureFlag = featureflagStore.getState().featureFlags;
  return (
    latestFeatureFlag.enable_new_renter_invites_for_smb &&
    user &&
    (user.LLType === LLTypes.SMB || checkIfPmi(user))
  );
};
