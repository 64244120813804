export const RiCoverageToLabelMap = {
  deductible: 'Deductible',
  dwellingCoverage: 'Dwelling coverage',
  earthquake: 'Earthquake',
  hurricane: 'Hurricane',
  identityFraud: 'Identity fraud',
  lossOfUse: 'Loss of use',
  medicalPayments: 'Medical liability',
  personalLiabilityCoverage: 'Personal liability',
  personalPropertyCoverage: 'Personal property',
  petDamage: 'Pet damage',
  sewerBackupCoverage: 'Sewer backup',
  theft: 'Theft',
  waterBackup: 'Water damage',
  windHail: 'Wind & hail',
} as const;
