import { AnalyticsBrowser } from '@segment/analytics-next';
import getEnv from '../env';
import { User } from './types/User';
import useABTestStore from '../stores/abTestStore';
import { cachedSessionStorage } from './cachedSessionStorage';

export const analytics = getEnv().segment_api_key
  ? AnalyticsBrowser.load({ writeKey: getEnv().segment_api_key })
  : null;

let prevPath: string | null = null;

function trackCurrentPage(pathname: string) {
  if (!getEnv().segment_api_key) {
    console.error('Segment API key missing, tracking disabled');
  }

  if (analytics && pathname !== prevPath) {
    const referrer = prevPath ?? document.referrer ?? '';
    prevPath = pathname;

    const campaign = cachedSessionStorage.getUTM();

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(campaign)) {
      searchParams.set(`utm_${key}`, value as string);
    }

    // See: https://segment.com/docs/guides/how-to-guides/cross-channel-tracking/
    analytics.page(pathname, {
      abTests: useABTestStore.getState().ABTestsValues,
      search: searchParams.toString(),
      referrer,
      context: {
        campaign,
      },
    });
  }
}

function trackEvent(eventName: string, payload: { [key: string]: any }) {
  if (analytics) {
    analytics.track(eventName, {
      ...payload,
      abTests: useABTestStore.getState().ABTestsValues,
      context: {
        ...(payload?.context || {}),
      },
    });
  }
}

function identifyUser(user: User) {
  if (analytics) {
    analytics.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      sizeOfPortfolio: user?.sizeOfPortfolio ?? undefined,
    });
  }
}

function setAnonymousUserId(id: string) {
  if (analytics && getEnv().segment_api_key) {
    return analytics.setAnonymousId(id);
  }
}

export { trackCurrentPage, trackEvent, identifyUser, setAnonymousUserId };
