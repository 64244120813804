import { useLocation } from 'react-router';
import { routes } from '../constants/Constants';
import { PublicRoutes } from '../routes/PublicRoutes';

export const useUrlChecker = () => {
  const location = useLocation();
  const isSigningUp =
    location.pathname === routes.numberUnitsOwned ||
    location.pathname === routes.registerEmail ||
    location.pathname === routes.brokerInvite;

  const isPublic =
    PublicRoutes.map((r) => r.path).indexOf(location.pathname) !== -1 &&
    !(location.pathname === '/');

  return { isSigningUp, isPublic };
};
