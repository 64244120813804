export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const capitalizeAtFirst = (str: string): string => {
  return str.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' ');
};

export const keepNumbers = (str: string): string => {
  return str.replace(/[^\d]/g, '');
};

export const formatPrice = (value: string): string => {
  if (!value) return value;

  const price = value.replace(/[^\d]/g, '');

  const priceLength = price.length;

  if (priceLength < 4) return price;

  if (priceLength < 7) {
    return `${price.slice(0, -3)},${price.slice(-3)}`;
  }

  return price;
};

export const checkNormalizeString = (str: string): string => {
  // Normalize the string by replacing diacritic characters with their base characters
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Replace multiple spaces with a single space
  str = str.replace(/\s+/g, ' ').trim();

  str = str.replace(/\n|\r/g, '');

  return str;
};

export const formatPhoneNumber = (value: string | undefined) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const convertToTestIdFormat = (inputString: string) => {
  const formattedString: string = inputString.toLowerCase().split(' ').join('_');

  return formattedString;
};
