import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import useAuthStore from '../../stores/authStore';
import logout from './logout';
import { externalConnections } from '../../constants/Constants';

let failedQueue: any = [];
let isRefreshing = false;

const processQueue = (error: any) => {
  failedQueue.forEach((prom: { reject: (arg0: any) => void; resolve: () => void }) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

export function createAxiosClient({ options }: { options: AxiosRequestConfig }) {
  const client = axios.create(options);
  client.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      config.headers.Authorization = 'Bearer ' + useAuthStore.getState().accessToken;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      // In "axios": "^1.1.3" there is an issue with headers, and this is the workaround.
      // originalRequest.headers = JSON.parse(JSON.stringify(originalRequest.headers || {}));
      const refreshToken = useAuthStore.getState().refreshToken;
      // If error, process all the requests in the queue and logout the user.
      const handleError = (error: any) => {
        processQueue(error);
        logout();
        return Promise.reject(error);
      };

      // Refresh token conditions
      if (
        refreshToken &&
        error.response?.status === 401 &&
        error.response.data.message === 'Unauthorized' &&
        originalRequest?.url !== externalConnections.refreshTokenUrl &&
        originalRequest?._retry !== true
      ) {
        if (isRefreshing) {
          try {
            await new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            });
            return await client(originalRequest);
          } catch (err) {
            return await Promise.reject(err);
          }
        }
        isRefreshing = true;
        originalRequest._retry = true;
        return await axios({
          url: externalConnections.refreshTokenUrl,
          data: { refresh_token: refreshToken },
          method: 'POST',
        })
          .then((res) => {
            const tokens = {
              accessToken: res.data?.access_token,
              refreshToken: res.data?.refresh_token,
            };
            useAuthStore.getState().setTokens(tokens);
            processQueue(null);

            return client(originalRequest);
          }, handleError)
          .finally(() => {
            isRefreshing = false;
          });
      }

      // Refresh token missing or expired => logout user...
      if (
        error.response?.status === 401 ||
        error.response?.data?.message === 'Unauthorized' ||
        error.response?.status === 403
      ) {
        return handleError(error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );

  return client;
}
