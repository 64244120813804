import { useMutation } from '@tanstack/react-query';
import { apiUri } from '@/constants/Constants';
import { client } from '@/utils/axios/axiosClient';
import { time } from '../libs';
import { DateFormat } from '../constants';

const getFilename = () => {
  const filename = `Renters-insurance-applications-${time().format(DateFormat.Calendar)}`;
  return filename;
};

export async function exportCsvRiApplication(): Promise<{ blob: Blob; filename: string }> {
  const response = await client.get(`${apiUri.listRiApplications}/export-csv`, {
    responseType: 'blob',
    withCredentials: true,
  });

  const filename = getFilename();
  return { blob: response.data, filename };
}

export function useExportCsvRiApplication() {
  return useMutation({
    mutationFn: exportCsvRiApplication,
  });
}
