import React from 'react';
import { routes } from '../constants/Constants';
const CheckYourEmail = React.lazy(() => import('../features/Registration/CheckYourEmail'));
const UnitsOwned = React.lazy(() => import('../features/Registration/UnitsOwned'));
const RegisterAndAgree = React.lazy(() => import('../features/Registration/RegisterAndAgree'));
const BrokerInvite = React.lazy(() => import('../features/Broker/BrokerSignup'));
const YouAreAllSet = React.lazy(() => import('../features/PreEnrollment/YouAreAllSet'));
const ThankYouPage = React.lazy(() => import('../features/PreEnrollment/ThankYouPage'));
const ErrorPage = React.lazy(() => import('../features/Home/ErrorPage'));
const Limitation = React.lazy(() => import('../features/PreEnrollment/Limitations'));
const EnrollNotEligiblePage = React.lazy(
  () => import('../features/EnrollProperty/EnrollPropertyForm/EnrollPropertyNotEligible'),
);

export const PublicRoutes = [
  {
    path: routes.numberUnitsOwned,
    component: UnitsOwned,
    title: 'Tell us about yourself | TheGuarantors',
  },
  {
    path: routes.checkEmail,
    component: CheckYourEmail,
    title: 'Confirm your email | TheGuarantors',
  },
  {
    path: routes.brokerInvite,
    component: BrokerInvite,
    title: 'Complete your enrollment | TheGuarantors',
  },
  {
    path: routes.emailSuccess,
    component: YouAreAllSet,
    title: `You're done | TheGuarantors`,
  },
  {
    path: routes.registerEmail,
    component: RegisterAndAgree,
    title: 'We need a few more details | TheGuarantors',
  },
  {
    path: routes.enrollNotEligible,
    component: EnrollNotEligiblePage,
    title: 'Property not eligible | TheGuarantors',
  },
  {
    path: routes.limitation,
    component: Limitation,
    title: 'No entry allowed here',
  },
  {
    path: routes.thankYou,
    component: ThankYouPage,
    title: 'Thank you | TheGuarantors',
  },
  {
    path: routes.error500,
    component: ErrorPage,
    title: 'Sorry, page not found',
  },
].map((route) => {
  return {
    ...route,
    role: 'none',
  };
});
