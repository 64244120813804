import { toInteger } from '../utils';
import {
  RiSearchParamKeys,
  DefaultRiPagination,
  RiApplicationComplianceStatusEnum,
  RiApplicationPolicyStatusEnum,
  RiApplicationSourceQueryParamsEnum,
  RiSortByParamEnum,
  RiSortOrderParamEnum,
  DefaultRiSort,
} from '../constants';
import { ObjectValues } from '@/features/RentersInsurance/types';

export const parsePaginationFromParams = (searchParams: URLSearchParams) => {
  const page = searchParams.get(RiSearchParamKeys.Page);
  const limit = searchParams.get(RiSearchParamKeys.Limit);

  const currentPage = toInteger(page, {
    fallbackValue: DefaultRiPagination.Page,
  });
  const currentLimit = toInteger(limit, {
    fallbackValue: DefaultRiPagination.Limit,
  });

  return { currentPage, currentLimit };
};

export const parseFiltersFromParams = (searchParams: URLSearchParams) => {
  const search = searchParams.get('search');
  const source = searchParams.get('source');
  const complianceStatus = searchParams.get('complianceStatus');
  const policyStatus = searchParams.get('policyStatus');

  const sourceArray = source?.split(',') as RiApplicationSourceQueryParamsEnum[] | undefined;
  const isSourceArrayValid = sourceArray?.every((sourceItem) =>
    Object.values(RiApplicationSourceQueryParamsEnum).includes(sourceItem),
  );

  const complianceStatusArray = complianceStatus?.split(',') as
    | RiApplicationComplianceStatusEnum[]
    | undefined;
  const isComplianceStatusValid = complianceStatusArray?.every((complianceStatusItem) =>
    Object.values(RiApplicationComplianceStatusEnum).includes(complianceStatusItem),
  );

  const policyStatusArray = policyStatus?.split(',') as RiApplicationPolicyStatusEnum[] | undefined;
  const isPolicyStatusValid = policyStatusArray?.every((policyStatusItem) =>
    Object.values(RiApplicationPolicyStatusEnum).includes(policyStatusItem),
  );

  return {
    search: search ?? undefined,
    source: isSourceArrayValid ? sourceArray : undefined,
    complianceStatus: isComplianceStatusValid ? complianceStatusArray : undefined,
    policyStatus: isPolicyStatusValid ? policyStatusArray : undefined,
  };
};

export function parseSortFromParams(searchParams: URLSearchParams) {
  const sortBy = searchParams.get(RiSearchParamKeys.SortBy);
  const sortOrder = searchParams.get(RiSearchParamKeys.SortOrder);

  const allowedSortByValues = Object.values(RiSortByParamEnum);
  const allowedSortOrderValues = Object.values(RiSortOrderParamEnum);

  const isSortByValid = allowedSortByValues.includes(
    sortBy as ObjectValues<typeof RiSortByParamEnum>,
  );
  const isSortOrderValid = allowedSortOrderValues.includes(
    sortOrder as ObjectValues<typeof RiSortOrderParamEnum>,
  );

  return {
    sortBy: isSortByValid ? (sortBy as (typeof allowedSortByValues)[number]) : DefaultRiSort.SortBy,
    sortOrder: isSortOrderValid
      ? (sortOrder as (typeof allowedSortOrderValues)[number])
      : DefaultRiSort.SortOrder,
  };
}
