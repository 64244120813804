import { ComponentPropsWithoutRef, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { DefaultRiPagination } from '../../constants';
import { DataTestId, EventFor, Except, MuiSxCollection } from '../../types';
import { getTotalPages, mergeSx, toInteger } from '../../utils';
import { Skeleton } from '@mui/material';
import { RiTableEmpty } from './RiTableEmpty';
import { RiTableError } from './RiTableError';
import palette, { riPalette } from '@/theme/themePalette';

type RiTableRootProps = Except<ComponentPropsWithoutRef<typeof Table>, 'slot'> & {
  tableContainerProps?: Except<
    ComponentPropsWithoutRef<typeof TableContainer>,
    'component' | 'children'
  > &
    DataTestId;
  wrapperBoxProps?: Except<ComponentPropsWithoutRef<typeof Box>, 'children'> & DataTestId;
  /** Any additional content to render above the table inside the TableContainer */
  headerSlot?: ReactNode;
  /** Footer for the table */
  footer?: ReactNode;
  /** Layout mode for the table (automatically adjust column width or fixed width) */
  layoutMode?: 'auto' | 'fixed';
};

const riTableRootStyles = {
  wrapperBox: {
    border: (theme) => `1px solid ${theme.palette.border.secondary}`,
    borderRadius: '5px',
    overflow: 'hidden',
  },
  tableContainer: {
    borderBottom: '1px solid rgba(8, 10, 45, 0.3)',
    overflowX: 'auto',
    border: 'none',
    borderRadius: 0,
  },
  tableElement: {
    width: '100%',
  },
} satisfies MuiSxCollection;

function RiTableRoot({
  tableContainerProps,
  wrapperBoxProps,
  headerSlot,
  footer,
  children,
  sx,
  layoutMode,
  ...props
}: RiTableRootProps) {
  const { sx: tableContainerSx, ...tableContainerRestProps } = tableContainerProps ?? {};
  const { sx: wrapperBoxSx, ...wrapperBoxRestProps } = wrapperBoxProps ?? {};

  const combinedWrapperBoxSx = mergeSx(riTableRootStyles.wrapperBox, wrapperBoxSx);
  const combinedTableContainerSx = mergeSx(riTableRootStyles.tableContainer, tableContainerSx);
  const combinedSx = mergeSx(
    {
      tableLayout: layoutMode === 'fixed' ? 'fixed' : 'auto',
    },
    riTableRootStyles.tableElement,
    sx,
  );

  return (
    <Box sx={combinedWrapperBoxSx} {...wrapperBoxRestProps}>
      {headerSlot}
      <TableContainer sx={combinedTableContainerSx} {...tableContainerRestProps} component={Paper}>
        <Table sx={combinedSx} {...props}>
          {children}
        </Table>
      </TableContainer>
      {footer}
    </Box>
  );
}

type RiTableHeaderProps = ComponentPropsWithoutRef<typeof TableHead>;
const riTableHeaderStyles = {
  tableHeader: {
    '& > tr.MuiTableRow-head': {
      backgroundColor: '#080A2D0A',
    },
  },
} satisfies MuiSxCollection;
function RiTableHeader({ children, sx, ...props }: RiTableHeaderProps) {
  return (
    <TableHead sx={mergeSx(riTableHeaderStyles.tableHeader, sx)} {...props}>
      {children}
    </TableHead>
  );
}

type RiTableHeadSortDirection = 'asc' | 'desc' | false | undefined;
type RiTableHeadProps = Except<ComponentPropsWithoutRef<typeof TableCell>, 'component'> & {
  colSpan?: number;
  /** Whether the column is sortable, controls the visibility of the sort icon */
  sortable?: boolean;
  /**
   * Direction of the sort
   * If `false` or `undefined`, it means the column is not sorted
   */
  sortDirection?: RiTableHeadSortDirection;
  /** Callback to handle sorting */
  onSort?: (direction: 'asc' | 'desc') => void;
};
const riTableHeadStyles = {
  tableHead: {
    padding: '16px',
    lineHeight: '24px',
    [`&:hover .ri-table-head-sort-icon`]: {
      opacity: 1,
    },
  },
  tableHeadSortable: {
    cursor: 'pointer',
  },
  sortSpan: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '16px',
    height: '24px',
    position: 'relative',
    boxSizing: 'border-box',
    border: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
    paddingBottom: '2px',
    color: riPalette.text.secondary,
    opacity: 0,
    [`&:hover`]: {
      color: palette.text.primary,
    },
  },
  sortSpanActive: {
    opacity: 1,
    color: palette.text.primary,
  },
  sortIcon: {
    width: '100%',
    height: '100%',
  },
  sortIconDesc: {
    transform: 'rotate(180deg)',
  },
} satisfies MuiSxCollection;
function RiTableHead({
  children,
  sx,
  sortable,
  sortDirection,
  onSort,
  ...props
}: RiTableHeadProps) {
  const combinedSx = mergeSx(
    riTableHeadStyles.tableHead,
    sx,
    sortable && riTableHeadStyles.tableHeadSortable,
  );

  function handleSort() {
    if (!sortable || !onSort) {
      return;
    }
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    onSort(newDirection);
  }

  return (
    <TableCell
      onClick={handleSort}
      component={'th'}
      sx={combinedSx}
      {...props}
      sortDirection={sortDirection ?? undefined}
    >
      {children}
      {sortable ? (
        <Box
          className="ri-table-head-sort-icon"
          component="span"
          sx={mergeSx(
            riTableHeadStyles.sortSpan,
            sortDirection && riTableHeadStyles.sortSpanActive,
          )}
        >
          <ArrowUpwardIcon
            sx={mergeSx(
              riTableHeadStyles.sortIcon,
              sortDirection === 'desc' && riTableHeadStyles.sortIconDesc,
            )}
          />
        </Box>
      ) : null}
    </TableCell>
  );
}

type RiTableRowProps = ComponentPropsWithoutRef<typeof TableRow>;
function RiTableRow({ children, ...props }: RiTableRowProps) {
  return <TableRow {...props}>{children}</TableRow>;
}

type RiTableCellProps = ComponentPropsWithoutRef<typeof TableCell>;
const riTableCellStyles = {
  tableCell: {
    padding: '16px',
    lineHeight: '26px',
  },
} satisfies MuiSxCollection;
function RiTableCell({ children, sx, ...props }: RiTableCellProps) {
  const combinedSx = mergeSx(riTableCellStyles.tableCell, sx);

  return (
    <TableCell sx={combinedSx} {...props}>
      {children}
    </TableCell>
  );
}

type RiTableBodyProps = ComponentPropsWithoutRef<typeof TableBody> & {
  /** Whether the table is empty */
  isEmpty?: boolean;
  /**
   * Component to render when the table is empty
   * By default, it uses `RiTableEmpty` component
   */
  emptyComponent?: React.ReactNode;
  /** Whether the table has an error */
  isError?: boolean;
  /**
   * Component to render when the table has an error
   * By default, it uses `RiTableError` component
   */
  errorComponent?: React.ReactNode;
  /** Whether the table is in a loading state */
  isLoading?: boolean;
  /**
   * Component to render while loading
   * By default, it uses `RiTableLoadingRow` to render 20 rows
   */
  loadingComponent?: React.ReactNode;
  /**
   * How many rows to render while loading.
   * It is ignored if loadingComponent is provided.
   * @default 20
   */
  loadingRows?: number;
  /**
   * How many columns to render while loading.
   * It is ignored if loadingComponent is provided.
   * @default 1
   */
  loadingColumns?: number;
};
function RiTableBody({
  isEmpty,
  emptyComponent,
  isError,
  errorComponent,
  children,
  isLoading,
  loadingComponent,
  loadingColumns = 1,
  loadingRows = 20,
  ...props
}: RiTableBodyProps) {
  if (isLoading) {
    const usedLoadingComponent =
      loadingComponent ??
      Array.from({ length: loadingRows }, (_, index) => (
        <RiTableLoadingRow columns={loadingColumns} key={index} />
      ));

    return <TableBody {...props}>{usedLoadingComponent}</TableBody>;
  }

  if (isEmpty) {
    const usedEmptyComponent = emptyComponent ?? <RiTableEmpty />;

    return <TableBody {...props}>{usedEmptyComponent}</TableBody>;
  }

  if (isError) {
    const usedErrorComponent = errorComponent ?? <RiTableError />;

    return <TableBody {...props}>{usedErrorComponent}</TableBody>;
  }

  return <TableBody {...props}>{children}</TableBody>;
}

type RiTableFooterProps = Except<ComponentPropsWithoutRef<typeof Box>, 'component'> & {
  tableFooterCellProps?: Except<ComponentPropsWithoutRef<typeof TableCell>, 'component'> &
    DataTestId;
};

const riTableFooterStyles = {
  tableRooterCell: {
    paddingLeft: '16px',
    paddingY: '4px',
  },
  tableFooterBox: {
    width: '100%',
  },
} satisfies MuiSxCollection;

function RiTableFooter({ children, sx, tableFooterCellProps, ...props }: RiTableFooterProps) {
  const { sx: tableFooterCellSx, ...tableFooterCellRestProps } = tableFooterCellProps ?? {};

  const combinedTableFooterCellSx = mergeSx(riTableFooterStyles.tableRooterCell, tableFooterCellSx);
  const combinedTableFooterBoxSx = mergeSx(riTableFooterStyles.tableFooterBox, sx);

  return (
    <Box sx={combinedTableFooterCellSx} {...tableFooterCellRestProps}>
      <Box sx={combinedTableFooterBoxSx} {...props}>
        {children}
      </Box>
    </Box>
  );
}

type RiTablePaginationProps = Except<
  ComponentPropsWithoutRef<typeof TablePagination>,
  'component' | 'page' | 'onPageChange' | 'ActionsComponent' | 'onRowsPerPageChange'
> & {
  /** Current page number (1-indexed) */
  page: number;
  /** Callback to handle page change */
  onPaginate: (page: number) => void;
  /** Callback to handle rows per page change */
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  /** Is the table in a loading state */
  isLoading?: boolean;
  /** Props for the Skeleton component when loading */
  skeletonProps?: ComponentPropsWithoutRef<typeof Skeleton> & DataTestId;
};
const riTablePaginationStyles = {
  tablePaginationRoot: {
    borderBottom: 0,
    '& .MuiTablePagination-root': {
      display: 'flex',
      borderBottom: 0,
    },
    '& > .MuiTablePagination-toolbar': {
      minHeight: 'auto',
    },
    '& > .MuiTablePagination-toolbar > .MuiTablePagination-selectLabel': {
      order: 1,
      marginY: 0,
      lineHeight: 'normal',
    },
    '& > .MuiTablePagination-toolbar > .MuiTablePagination-input': {
      order: 2,
      marginRight: '16px',
      fontSize: '12px',
      '@media (max-width: 900px)': {
        fontSize: '12px',
      },
    },
    '& > .MuiTablePagination-toolbar > .MuiTablePagination-displayedRows': {
      order: 3,
      display: 'none',
    },
  },
} satisfies MuiSxCollection;
function RiTablePagination({
  count,
  page,
  onPaginate,
  onRowsPerPageChange,
  isLoading,
  skeletonProps,
  ...props
}: RiTablePaginationProps) {
  const currentPage = page - 1;

  function onPageChange(_event: unknown, newPage: number) {
    onPaginate(newPage + 1);
  }

  function handleRowsPerPageChange(event: EventFor<'input', 'onChange'>) {
    const target = event.target as HTMLInputElement;
    const newRowsPerPage = toInteger(target.value, {
      fallbackValue: DefaultRiPagination.Limit,
    });

    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRowsPerPage);
    }
  }

  if (isLoading) {
    return <Skeleton height={48} width={300} {...skeletonProps} />;
  }

  return (
    <TablePagination
      sx={riTablePaginationStyles.tablePaginationRoot}
      component={'div'}
      page={count === 0 ? 0 : currentPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      count={count === 0 ? 1 : count}
      ActionsComponent={RiTablePaginationActions}
      {...props}
    />
  );
}

type RiTablePaginationActionsProps = TablePaginationActionsProps;
const riTablePaginationActionsStyles = {
  root: {
    order: 4,
    display: 'flex',
    alignItems: 'center',
  },
  currentPage: {
    whiteSpace: 'nowrap',
    fontSize: '12px',
    '@media (max-width: 900px)': {
      fontSize: '12px',
    },
  },
  actionButton: {
    padding: '12px',
  },
} satisfies MuiSxCollection;
function RiTablePaginationActions({
  count,
  onPageChange,
  page,
  rowsPerPage,
}: RiTablePaginationActionsProps) {
  const totalPages = getTotalPages(count, rowsPerPage);

  function handlePreviousPage(event: EventFor<'button', 'onClick'>) {
    onPageChange(event, page - 1);
  }

  function handleNextPage(event: EventFor<'button', 'onClick'>) {
    onPageChange(event, page + 1);
  }

  return (
    <Box sx={riTablePaginationActionsStyles.root}>
      <IconButton
        aria-label="Previous page"
        onClick={handlePreviousPage}
        disabled={page === 0}
        sx={riTablePaginationActionsStyles.actionButton}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Typography sx={riTablePaginationActionsStyles.currentPage}>
        {page + 1} of {totalPages}
      </Typography>
      <IconButton
        aria-label="Next page"
        onClick={handleNextPage}
        disabled={page >= totalPages - 1}
        sx={riTablePaginationActionsStyles.actionButton}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

interface RiTableLoadingRowProps {
  /** How many columns to render while loading */
  columns: number;
}
function RiTableLoadingRow({ columns }: RiTableLoadingRowProps) {
  const usedColumns = Array.from({ length: columns });
  const colSpan = columns > 1 ? undefined : 1000;

  return (
    <RiTableRow>
      {usedColumns.map((_, index) => (
        <RiTableCell key={index} colSpan={colSpan}>
          <Skeleton height={28} />
        </RiTableCell>
      ))}
    </RiTableRow>
  );
}

export {
  RiTableRoot,
  RiTableHeader,
  RiTableHead,
  RiTableRow,
  RiTableCell,
  RiTableBody,
  RiTableFooter,
  RiTablePagination,
  RiTablePaginationActions,
  RiTableLoadingRow,
};
