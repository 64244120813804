import { useTheme, Box, Divider, Typography } from '@mui/material';
import Logo from './../assets/images/footer-logo.png';
import { externalLinks, footerMenu, SocialLinks } from '../constants/Constants';
import useFeatureFlagStore from '../stores/featureflagStore';
import BCorp from './../assets/images/b-corp-small.png';
import { goToExternal } from '../utils/linkUtils';
import { Link } from '../components/Link';

const SiteFooterPublic = () => {
  const theme = useTheme();
  const { featureFlags } = useFeatureFlagStore();
  const currentYear = new Date().getFullYear();

  const footerMenuLinks = featureFlags.enable_footer_trust_center
    ? footerMenu
    : footerMenu.filter((item) => item.to !== externalLinks.trustCenter);

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          px: { xs: 2.5, lg: 7.5 },
          py: { xs: 5, lg: 10 },
          mt: { xs: '48px' },
        }}
      >
        <Box
          component="img"
          onClick={() => {
            goToExternal(externalLinks.homePage, {
              title: 'Go to Main Page',
              path: '/home',
            });
          }}
          sx={{
            [theme.breakpoints.down('lg')]: {
              width: '315px',
              height: '40px',
            },
            width: '700px',
            height: '90px',
            cursor: 'pointer',
          }}
          src={Logo}
          alt="TheGuarantors"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginTop: { xs: '50px', sm: '0px' },
            [theme.breakpoints.down('sm')]: {
              alignItems: 'flex-start',
            },
          }}
        >
          <Link href={externalLinks.bCorpCertification} target="_blank" underline="none">
            <Box
              component="img"
              src={BCorp}
              alt="B Corp Certification"
              sx={{
                [theme.breakpoints.up('lg')]: {
                  height: '90px',
                  width: 'auto',
                  maxWidth: '54px',
                },
                height: '60px',
                width: 'auto',
                maxWidth: '36px',
              }}
            />
          </Link>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              gap: '15px',
              marginTop: { xs: '30px', sm: '20px' },
            }}
          >
            {SocialLinks.map((item, index) => (
              <Link
                key={index}
                target="_blank"
                href={item.link}
                underline="hover"
                color="primary.light"
                role="footerLinks"
              >
                <div className="footer-social-link">{item.icon}</div>
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          opacity: 0.5,
          borderTop: '0.5px solid',
          borderBottom: 'none',
          borderColor: theme.palette.primary.light,
        }}
      />

      <Box sx={{ px: { xs: 2.5, md: 3.75, lg: 7.5 } }}>
        <Box
          sx={{
            pt: {
              xs: '30px',
              md: '25px',
            },
          }}
        >
          <Typography
            variant="body1"
            color="grey.200"
            sx={{
              letterSpacing: '-.01em',
              fontSize: { sm: '14px', md: '12px', lg: '16px' },
              lineHeight: { xs: '22px', md: '24px', lg: '26px' },
            }}
          >
            TheGuarantors is a licensed insurance agent and broker. For more detailed information,
            please see our{' '}
            <Link
              underline="always"
              data-variant="noColor"
              target="_blank"
              sx={{
                lineHeight: { xs: '22px', md: '24px', lg: '26px' },
                textDecorationColor: 'unset',
                ':hover': { color: 'primary.light' },
              }}
              href={externalLinks.legalRegulatory}
            >
              Legal/Regulatory Notice
            </Link>
            . CA: Guarantors Insurance Agency, Lic. No. 0M44435. AZ, CT, DE, KS, ME, MI, MT, NE, NH,
            NJ, NC, ND, OH, OK, OR, SC, WV: The Guarantors Agency. All coverages are subject to the
            terms, conditions and exclusions of the actual policy issued. Not all policies or
            coverages are available in every state.
          </Typography>
        </Box>
        <Box py="30px">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(12, 1fr)',
              gridColumn: '1 / 13',
              gap: '30px',
              columnGap: '48px',
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: '24px 30px',
              },
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
              },
              [theme.breakpoints.up('lg')]: {
                display: 'grid',
                gap: '30px',
              },
            }}
          >
            <Typography
              variant="body1"
              color="grey.200"
              sx={{
                fontSize: { sm: '14px', md: '12px', lg: '16px' },
                gridColumn: '1 / 5',
              }}
            >
              © {currentYear} TheGuarantors
            </Typography>
            <Box
              sx={{
                display: 'grid',
                [theme.breakpoints.up('xs')]: {
                  gridTemplateColumns: '1fr 1fr',
                  gap: '15px 15px',
                },
                [theme.breakpoints.up('md')]: {
                  display: 'flex',
                  columnGap: 'inherit',
                  flexWrap: 'wrap',
                },
                [theme.breakpoints.up('lg')]: {
                  display: 'flex',
                  flexWrap: 'wrap',
                  gridColumn: '5 / 13',
                  columnGap: 'inherit',
                  justifyContent: 'flex-end',
                },
              }}
            >
              {footerMenuLinks.map((item, index) => (
                <Link
                  sx={{
                    fontSize: { sm: '14px', md: '12px', lg: '16px' },
                    [theme.breakpoints.down('md')]: {
                      ':nth-last-child(even)': {
                        marginLeft: '15px',
                      },
                    },
                    ':hover': { color: 'primary.light' },
                  }}
                  key={index}
                  variant="body1"
                  underline="hover"
                  color="grey.200"
                  target="_blank"
                  href={item.to}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteFooterPublic;
