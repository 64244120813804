import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { TableSearchField } from './TableSearchField';
import { PolicyTypeFilter } from './Filters/PolicyTypeFilter';
import { ComplianceStatusFilter } from './Filters/ComplianceStatusFilter';
import { RiListApplication } from '../../../types';
import { time } from '../../../libs';
import { DateFormat } from '../../../constants';
import { json2csv } from 'json-2-csv';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ExportButtonDropdown } from './ExportButtonDropdown';
import { useToast } from '@/components/Toast';
import { useSearchParams } from 'react-router-dom';
import { resetPageSearchParam } from '@/utils/purge-search-params.util';
import {
  getReasonsNotCompliantMessage,
  mapRiApplicationSourceToDisplayedValue,
} from '@/features/RentersInsurance/utils';

const ContainerStyled = styled(Box)(({ theme }) => ({
  padding: '12px 16px',
  backgroundColor: '#080A2D0A',
  borderBottom: `1px solid ${theme.palette.border.secondary}`,
}));

const FilterCounterBoxStyled = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  fontSize: '12px',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '8px 12px',
}));

type Props = {
  rowsToExport: RiListApplication[];
  isDataLoading: boolean;
};

const getFilterCount = (searchParams: URLSearchParams) => {
  const source = searchParams.get('source');
  const complianceStatus = searchParams.get('complianceStatus');
  const policyStatus = searchParams.get('policyStatus');

  return [source, complianceStatus, policyStatus].filter((item) => item?.length).length;
};

const parseToCsvData = (row: RiListApplication) => {
  const {
    address,
    firstName,
    buildingId,
    complianceStatus,
    email,
    lastName,
    source,
    reasonsNotCompliant,
    updatedAt,
    buildingUnit,
  } = row;

  return {
    'Building name': address?.addressName,
    Unit: buildingUnit,
    Email: email,
    'First name': firstName,
    'Last name': lastName,
    'Policy type': mapRiApplicationSourceToDisplayedValue(source),
    'Building ID': buildingId,
    'Last updated': updatedAt,
    Status: complianceStatus?.toString(),
    'Not compliant reasons': getReasonsNotCompliantMessage(reasonsNotCompliant).join(', '),
  };
};

export function RiApplicationTableHeader({ rowsToExport, isDataLoading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { success } = useToast();
  const filterCount = getFilterCount(searchParams);

  const isExportButtonDisabled = rowsToExport.length === 0 || isDataLoading;

  const handleFiltersClear = () => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.delete('source');
      prevSearchParams.delete('complianceStatus');
      prevSearchParams.delete('policyStatus');
      resetPageSearchParam(prevSearchParams);

      return prevSearchParams;
    });
  };

  const handleRowsExport = () => {
    const numberOfRows = rowsToExport.length;

    const jsonData = rowsToExport.map((row) => parseToCsvData(row));
    const csvContent = json2csv(jsonData, {});

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Renters-insurance-applications-${time().format(DateFormat.Calendar)}`;
    link.click();

    success(`${numberOfRows} ${numberOfRows === 1 ? 'item' : 'items'} successfully exported`);
  };

  return (
    <ContainerStyled data-testid="ri-applications-table-header">
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Stack flexDirection="row" alignItems="center">
          <TableSearchField label="Search by renter name or email" />

          <Stack flexDirection="row" alignItems="center" sx={{ mx: 2 }}>
            <PolicyTypeFilter />
            <ComplianceStatusFilter />
          </Stack>
          {filterCount > 0 ? (
            <FilterCounterBoxStyled>
              <span data-testid="filter-count-number">
                {filterCount} {filterCount > 1 ? 'filters' : 'filter'} applied
              </span>
              <IconButton
                data-testid="clear-filter-btn"
                disableRipple={true}
                size="small"
                sx={{ p: 0, fontSize: '16px', ml: '8px' }}
                onClick={handleFiltersClear}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </FilterCounterBoxStyled>
          ) : null}
        </Stack>

        <ExportButtonDropdown
          isExportButtonDisabled={isExportButtonDisabled}
          onExport={handleRowsExport}
        />
      </Stack>
    </ContainerStyled>
  );
}
