import { apiUri } from '../../constants/Constants';
import { ABTests, Experiment } from '../../stores/abTestStore';
import { setAnonymousUserId } from '../analytics';
import { client } from '../axios/axiosClient';
import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';

const getEntityId = async () => {
  const cookieId = Cookie.get('ajs_anonymous_id');
  if (cookieId) {
    return cookieId;
  } else {
    return await setAnonymousUserId(uuidv4());
  }
};

export const getABTests = async () => {
  const entityId = await getEntityId();
  return await client
    .get(`${apiUri.getABTests}${entityId || ''}`)
    .then((value: { data: Experiment[] }) => {
      const data: ABTests = {};
      value.data.forEach((experiment) => {
        data[experiment.featureName] = experiment;
      });
      return data;
    });
};

export interface Metric {
  metricsKey: string;
  metricsValue: number;
}

interface MetricsBody {
  entityId: string;
  metricsArray: Metric[];
}

export const sendABMetrics = async (data: MetricsBody) => {
  return await client.post(apiUri.sendExperiment, data);
};
