import { routes } from '../constants/Constants';
import { PrivateRoutes } from '../routes/PrivateRoutes';
import { PublicRoutes } from '../routes/PublicRoutes';
import { trackCurrentPage } from './analytics';

export function updateUrlTitle() {
  window.scrollTo({ top: 0 });

  if (!location.pathname || location.pathname === routes.home) return;
  trackCurrentPage(location.pathname);

  const oldTitle = document.title;
  const cleanedUrlPath = [...PublicRoutes, ...PrivateRoutes].find((path) => {
    return path.path === location.pathname;
  });

  const newTitle = `${cleanedUrlPath?.title}`;
  if (oldTitle === newTitle) return;
  document.title = newTitle;
}
