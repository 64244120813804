import { RiApplicationTableColumnIds } from '@/features/RentersInsurance/constants/ri-application-table.constant';

export const DefaultRiPagination = {
  Page: 1,
  Limit: 20,
};

export const RiSearchParamKeys = {
  Page: 'page',
  Limit: 'limit',
  SortBy: 'sort',
  SortOrder: 'order',
} as const;

export enum RiSortByParamEnum {
  Renter = 'renter',
  PolicyType = 'policyType',
  LastUpdated = 'lastUpdated',
  Status = 'status',
}

export enum RiSortOrderParamEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export const DefaultRiSort = {
  SortBy: RiSortByParamEnum.LastUpdated,
  SortOrder: RiSortOrderParamEnum.Desc,
};

export const RiSortByToApiSortMap = {
  [RiSortByParamEnum.Renter]: 'renter',
  [RiSortByParamEnum.PolicyType]: 'source',
  [RiSortByParamEnum.LastUpdated]: 'updated_at',
  [RiSortByParamEnum.Status]: 'compliance_status',
} as const;

export const RiSortToRiTableColumnIdMap = {
  [RiSortByParamEnum.Renter]: RiApplicationTableColumnIds.Renter,
  [RiSortByParamEnum.PolicyType]: RiApplicationTableColumnIds.Source,
  [RiSortByParamEnum.LastUpdated]: RiApplicationTableColumnIds.UpdatedAt,
  [RiSortByParamEnum.Status]: RiApplicationTableColumnIds.ComplianceStatus,
} as const;

export const RiTableColumnIdToSortMap = {
  [RiApplicationTableColumnIds.Renter]: RiSortByParamEnum.Renter,
  [RiApplicationTableColumnIds.Source]: RiSortByParamEnum.PolicyType,
  [RiApplicationTableColumnIds.UpdatedAt]: RiSortByParamEnum.LastUpdated,
  [RiApplicationTableColumnIds.ComplianceStatus]: RiSortByParamEnum.Status,
} as const;
