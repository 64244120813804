import axios from 'axios';
import useAuthStore from '../../stores/authStore';
import { externalConnections } from '../../constants/Constants';

const logout = async () => {
  useAuthStore.getState().logout();
  await axios.post(externalConnections.loginApiLogout, {
    refresh_token: useAuthStore.getState().refreshToken,
  });
  window.location.replace(externalConnections.logout);
};

export default logout;
