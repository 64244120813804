import { useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { routes } from '../constants/Constants';
import getUser from '../utils/apiRestCalls/getUser';
import Loader from './Loader';
import { PageWrapper } from './PageWrapper';
import { useEffect } from 'react';
import { useUrlChecker } from '../hooks/useUrlChecker';
import MyLandbot from '../components/Landbot';
import { checkIfUserCanEnrollProperties } from '../utils/commonUtils';
import getEnv from '../env';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { identifyUser } from '../utils/analytics';
import { sizeOfPortfolio } from '../features/Registration/constants/UnitOptions';
import { cachedSessionStorage } from '../utils/cachedSessionStorage';
import { Userpilot } from 'userpilot';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export default function SiteWrapper({ children }: Props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isPublic } = useUrlChecker();

  useEffect(() => {
    cachedSessionStorage.cacheStorage();
    // Take all query params for the future.
    cachedSessionStorage.storeUTM(searchParams);
  }, []);

  const {
    data: user,
    isLoading,
    status,
    error,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    staleTime: Infinity,
    enabled: !isPublic,
  });

  useEffect(() => {
    if (status === 'success') {
      identifyUser(user);
      Userpilot.identify(user.id || '', {
        name: user.firstName || '',
      });

      setTimeout(() => {
        if (user?.role !== 'landlord') return navigate(routes.limitation);
        if (
          user.sizeOfPortfolioOld === sizeOfPortfolio.fiftyOneToTwoHundred &&
          !user.status?.hasProperties
        ) {
          return navigate(routes.home, { replace: false });
        } else if (user?.status?.isNewSelfServiceUser) {
          return navigate(routes.propertyEnrollmentForm);
        }
      }, 0);
    }
  }, [status]);

  if (!isPublic && isLoading) return <Loader />;
  if (error) return <Navigate to={routes.error500} replace={true} />;

  return (
    <HelmetProvider>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={`//js.hs-scripts.com/${getEnv().hubspot_portal_id}.js`}
        ></script>
      </Helmet>
      {isPublic && !location.hostname.includes('localhost') ? (
        <Helmet>
          <script
            id="cookieyes"
            type="text/javascript"
            src="https://cdn-cookieyes.com/client_data/f4c8903f602bc4de68ebe271/script.js"
          ></script>
        </Helmet>
      ) : null}
      {user && checkIfUserCanEnrollProperties(user) && <MyLandbot />}
      <PageWrapper>{children}</PageWrapper>
    </HelmetProvider>
  );
}
