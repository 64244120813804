import React, { useState } from 'react';
import { FilterDropdown } from '../FilterDropdown';
import { useFilter } from '../../../../hooks';

const FILTER_KEY = 'complianceStatus';

const complianceStatusOptions = [
  {
    title: 'Compliant',
    name: 'compliant',
  },
  {
    title: 'Not compliant',
    name: 'not-compliant',
  },
  {
    title: 'In progress',
    name: 'in-progress',
  },
  {
    title: 'In review',
    name: 'needs-review',
  },
];

export function ComplianceStatusFilter() {
  const { getFilterOptions, handleFilterChange } = useFilter(FILTER_KEY);
  const initialOptions = getFilterOptions(complianceStatusOptions);
  const [options, setOptions] = useState(initialOptions);

  const handleOptionClick = (optionValue: string) => {
    const newOptions = options.map((option) =>
      option.name === optionValue ? { ...option, selected: !option.selected } : option,
    );

    setOptions(newOptions);
  };

  const handleOpen = () => {
    setOptions(initialOptions);
  };

  return (
    <FilterDropdown
      data-testid="compliance-status-filter"
      title="Status"
      options={options}
      onOpen={handleOpen}
      onOptionClick={handleOptionClick}
      onBlur={() => handleFilterChange(options)}
    />
  );
}
