export const DateFormat = {
  Calendar: 'YYYY-MM-DD',
  Us: 'MM/DD/YYYY',
  Timestamp: 'YYYY-MM-DDTHH:mm:ss.sssZ',
} as const;

export const TimeDuration = {
  Second: 1000,
  Minute: 60 * 1000,
} as const;
