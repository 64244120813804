export function generateUrlSearchParams<T extends object>(params: T): string {
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      const usedValue = Array.isArray(value) ? value.join(',') : value;
      urlParams.append(key, usedValue);
    }
  });

  return urlParams.toString();
}
