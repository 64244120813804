import React from 'react';
import { Checkbox, MenuItem } from '@mui/material';

type Props = {
  children: React.ReactNode;
  onClick: any;
  isChecked: boolean;
};

export function FilterDropdownItem({ isChecked, onClick, children }: Props) {
  return (
    <MenuItem onClick={onClick} data-testid="filter-menu-item" data-checked={isChecked}>
      <Checkbox checked={isChecked} />
      {children}
    </MenuItem>
  );
}
