import React from 'react';

function SvgComponent({ width = 30, height = 30, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...props}>
      <title>{'linkedin'}</title>
      <path
        d="M6.648 9.308c1.398-.006 2.633-1.078 2.632-2.62 0-1.542-1.253-2.607-2.6-2.61a2.615 2.615 0 10-.032 5.23m-2.245 9.096c0 2.316.002 4.631-.004 6.947-.001.201.06.255.257.254 1.33-.008 2.662-.007 3.993 0 .193.001.261-.047.261-.252-.005-4.631-.005-9.262 0-13.894 0-.2-.065-.256-.26-.255-1.325.007-2.65.003-3.974.004-.272 0-.273 0-.273.269v6.927m11.567-5.22c-.005-.104-.01-.167-.01-.23-.002-.498-.01-.997.003-1.496.006-.201-.06-.256-.256-.255-1.266.008-2.532.004-3.797.005-.26 0-.263.003-.263.266 0 4.625.001 9.251-.004 13.877 0 .2.062.255.259.254 1.318-.008 2.636-.004 3.954-.004.29 0 .291 0 .291-.293 0-2.296-.01-4.592.008-6.889.004-.553.06-1.114.166-1.657.143-.734.498-1.355 1.215-1.69.531-.247 1.094-.294 1.67-.225.768.09 1.293.495 1.584 1.209.213.524.29 1.078.293 1.635.012 2.552.01 5.105.006 7.657 0 .198.055.258.256.257 1.344-.008 2.688-.008 4.033 0 .204.002.253-.067.253-.26-.013-2.827-.01-5.655-.032-8.483-.008-1.005-.142-1.998-.469-2.957-.417-1.225-1.172-2.15-2.397-2.623-1.13-.437-2.304-.535-3.497-.326-1.14.2-2.093.735-2.834 1.63-.148.177-.272.375-.432.598m14.026 1.815c0 4.226-.002 8.453.002 12.679 0 .491-.076.955-.364 1.362-.438.621-1.039.951-1.801.956-1.07.007-2.138.002-3.208.002-7.47 0-14.941.001-22.412-.001-.936 0-1.619-.434-2.022-1.276-.102-.213-.129-.464-.176-.7-.022-.108-.012-.222-.012-.334 0-8.465.001-16.93-.003-25.396C0 1.755.099 1.26.439.835A2.137 2.137 0 012.085.001c.04-.002.08 0 .119 0 8.526 0 17.053-.002 25.58 0 .915 0 1.604.404 2.01 1.231.111.228.143.5.191.755.026.14.01.288.01.432V15"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

const LinkedinIcon = React.memo(SvgComponent);

LinkedinIcon.displayName = 'LinkedinIcon';

export default LinkedinIcon;
