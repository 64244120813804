import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import RegistrationProfile from '../features/PreEnrollment/RegistrationProfile';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useProfileDrawerStore from '../stores/useProfileDrawerStore';
import { trackEvent } from '../utils/analytics';
import { purgeUrlSearchParams } from '../utils/purge-search-params.util';

const ProfileDrawer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { profileDrawerShown, hideProfileDrawer, showProfileDrawer } = useProfileDrawerStore();

  useEffect(() => {
    if (!profileDrawerShown) return;

    trackEvent('Profile Drawer Shown', {
      title: 'PROFILE',
      url: location.pathname,
      path: location.pathname + '#profile-update',
      referrer: location.pathname,
    });
  }, [profileDrawerShown]);

  useEffect(() => {
    if (location?.hash === '#profile') {
      showProfileDrawer();
    }
  }, [location]);

  useEffect(() => {
    if (profileDrawerShown) {
      navigate({ hash: 'profile' });
    } else {
      navigate({ hash: '', search: purgeUrlSearchParams(location.search) });
    }
  }, [profileDrawerShown]);

  return (
    <Drawer
      anchor="right"
      open={profileDrawerShown}
      onClose={hideProfileDrawer}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(8, 10, 45, 0.1)' } } }}
    >
      <Stack p={3} justifyContent="center" alignItems="center" maxWidth={513}>
        <Stack direction="row" style={{ width: '100%' }} justifyContent="space-between">
          <Box>
            <Typography variant="h4" id="ProfileHeader">
              Profile
            </Typography>
            <Typography id="ProfileSubHeader" sx={{ marginTop: '8px', marginBottom: '24px' }}>
              Edit your profile information below.
            </Typography>
          </Box>
          <Box>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              style={{ position: 'relative', bottom: '10px' }}
              onClick={() => hideProfileDrawer()}
              edge="start"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
        <RegistrationProfile />
      </Stack>
    </Drawer>
  );
};
export default ProfileDrawer;
