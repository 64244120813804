import { AxiosResponse } from 'axios';
import { apiUri } from '../../constants/Constants';
import userValues from '../normalizeUser';
import { client } from '../axios/axiosClient';
import { UserResponse } from '../types/User';

const getUser = async () => {
  return await client
    .get(apiUri.getMe, { withCredentials: true })
    .then((resp: AxiosResponse<UserResponse>) => {
      return userValues(resp);
    });
};

export default getUser;
