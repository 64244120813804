import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import { useExportCsvRiApplication } from '../../../api/export-csv-ri-application.api';
import { useToast } from '@/components/Toast';
import { trackEvent } from '@/utils/analytics';
import { RiSegmentEvents } from '@/features/RentersInsurance/constants';

type Props = {
  isExportButtonDisabled: boolean;
  onExport: () => void;
};

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: 'white',
  border: `1px solid ${theme.palette.text.primary}`,
  padding: '16px 18px',
}));

export function ExportButtonDropdown({ isExportButtonDisabled, onExport }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { mutate: exportCsv, isPending: isExportingAll } = useExportCsvRiApplication();
  const { success, error: showErrorToast } = useToast();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    trackEvent(RiSegmentEvents.RiApplicationExportOptionClick, {
      selectedOption: 'Current Page',
    });
    onExport();
    handleClose();
  };

  const handleExportAll = async () => {
    trackEvent(RiSegmentEvents.RiApplicationExportOptionClick, {
      selectedOption: 'Underlying Page',
    });
    exportCsv(undefined, {
      onSuccess: (data) => {
        saveAs(data.blob, data.filename);
        success(`Underlying data successfully exported`);
        handleClose();
      },
      onError: (error) => {
        console.log(error);
        showErrorToast('Unable to export. Refresh the page and try again.');
        handleClose();
      },
    });
  };

  const buttonId = 'export-button-to-open-menu';
  const menuId = 'export-menu';

  return (
    <>
      <ButtonStyled
        id={buttonId}
        data-testid="table-export-btn"
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </ButtonStyled>
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disabled={isExportingAll || isExportButtonDisabled}
          onClick={handleExportAll}
          data-testid="table-export-current-rows-btn"
        >
          Underlying data
        </MenuItem>
        <MenuItem
          disabled={isExportButtonDisabled}
          onClick={handleExport}
          data-testid="table-export-current-rows-btn"
        >
          Current page
        </MenuItem>
      </Menu>
    </>
  );
}
